.template-home-page {
    .home-hero {
        .home-hero-images {
            animation: perspective-in 0.3s linear;
        }

        .home-hero-img {
            animation: rotate-img 0.3s linear;
        }

        .home-hero-txt {
            animation: fade-in 1s linear 0.3s forwards;
        }
    }
}

@keyframes perspective-in {
    0% {
        perspective: 0;
    }
    95% {
        perspective: 5px;
    }
    100% {
        perspective: 200px;
    }
}

@keyframes rotate-img {
    0% {
        transform: rotateY(20deg);
    }
    95% {
        transform: rotateY(20deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

.home-container {
    .why-nixa-home {
        .why-text-home {
            p, a, li {
                font-size: 16px;
                line-height: 26px;
                margin-top: 1rem;
            }
        }
    }

    .home-card-category-title {
        color: $grey-dark;
        cursor: pointer;
    }

    .home-card-date-category-divider {
        background-color: rgba(0, 0, 0, .6);
        width: 4px;
        height: 4px;
        display: flex;
        align-self: center;
        border-radius: 50%;
        margin: 0 7px;
        @media only screen and (max-width: 370px) {
            display: none;
        }
    }

    .home-card-date {
        color: rgba(0, 0, 0, .6);
        @media only screen and (max-width: 370px) {
            display: none;
        }
    }

    .industry-nixa {
        .mb-4 {
            margin-bottom: 2rem !important;
        }
    }

    .solutions-card {
        cursor: pointer;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 0 0 2px rgba(255, 255, 255, 0.15);
        border: none;
        box-sizing: border-box;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        padding: 30px;
        align-items: flex-start;
        gap: 15px;
        justify-content: flex-end;
        position: relative;

        .cont-img {
            position: absolute;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            perspective: 100px;
            z-index: -1;
            left: 0;
            top: 0;
            border-radius: 12px;
        }

        .solutions-card-heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            .solutions-card-icon {
                margin-right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 52px;
                min-width: 52px;
                border-radius: 50%;
                color: $white;
                font-family: $font-title;
                font-weight: 700;
                font-size: 16px;
            }

            .solutions-card-title {
                font-weight: 700;
                font-size: 18px;
                color: $white;
                padding-bottom: initial;
            }
        }

        .solutions-card-content {
            display: flex;
            flex-direction: column;
            font-family: $font-card;

            .solutions-card-txt {
                font-family: "Poppins", sans-serif;
                font-size: 12px;
                line-height: 1.5;
                color: $white;
                margin-bottom: 0;

                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    //nixa office  section start
    .nixa-offices-wrap {
        background-color: black;

        .nixa-offices {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;

            .nixa-office-logo-background {
                height: 100%;
                position: absolute;
                right: 46.9%;
                top: 0;
                opacity: 0.8;
            }

            .video-container {
                box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
                overflow: hidden;
                border-radius: 25px;
            }

            .nixa-office-image-text {
                padding: 50px 0;
                z-index: 10;
                color: $dark-white;
                display: flex;
                justify-content: start;
                align-items: center;
                width: 100%;

                p {
                    font-size: 16px !important;
                }

                img {
                    max-height: 300px;
                    width: 100%;
                    border-radius: 25px;
                }

                @media (max-width: 820px) {
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .video-block-title {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
        color: #FFFFFF;
    }

    .video-block-text {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
    }

    .full-width-video-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        border-radius: 32px;
        overflow: hidden;
        cursor: pointer;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .video-button {
            img {
                max-height: initial !important;
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 25px;
                position: absolute;
                z-index: 2;
            }
        }
    }

    .pre-footer-text-container {
        h2, h3, p, a, li {
            color: $grey-120;
            font-family: Poppins, sans-serif;
        }

        h2, h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 48px;
        }

        a {
            text-decoration: underline;
        }

        p, li, p a {
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
        }

    }

    .chat-bot-block {
        background-color: #262525;
        padding-top: 32px;
        padding-bottom: 32px;

        &__title {
            font-size: clamp(18px, 2vw, 20px);
        }

        &__text {
            p, a, li {
                font-size: clamp(16px, 2vw, 20px);
                font-style: normal;
                font-weight: 400;
                line-height: 26.4px;
                text-transform: uppercase;
            }
        }

        &__buttons {
            border: initial;
            background: initial;

            button img {
                width: 24px;
                height: 24px;
            }
        }

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: white;
        }

        .chat-bot {
            max-width: 700px;
            width: 100%;
            height: 100vh;
            max-height: 200px;
            overflow: hidden;
            transition: max-height 0.5s ease-in-out;
            position: relative;

            #iframe-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 10;
                display: none;
            }
        }

        .chat-bot-close-button--cross {
            position: absolute;
            right: 0;
            top: 0;
        }

        .chat-bot-open-button {
            transform: rotate(180deg);
        }

        #chat-bot-top-button-container {
            display: none;
        }
    }
}

.single-image-hero {
    padding-top: 48px;
    background-size: cover;
    background-color: transparent;
    background-position: center;

    .single-image-container {
        row-gap: 32px;
    }

    .rich-text {
        h2 {
            font-size: 1.2rem;
        }

        p {
            & b {
                color: $grey-90;
            }
        }
    }

    .btns-container {
        gap: 12px;

        @media (max-width: 1439px) {
            .bttn {
                padding: 12px 32px;
            }
        }

        @media (max-width: 1023px) {
            .bttn {
                padding: 12px 25px;
            }
        }

        @media (max-width: 767px) {
            .bttn {
                width: 100%;
                padding: 12px 24px;
            }
        }
    }

    .home-hero-image {
        aspect-ratio: 16 / 9;

        & img {
            border-radius: 8px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
