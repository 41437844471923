.text-cards {
    padding: 48px 24px;
    border: 1px solid $grey-40;

    &-container {
        margin-bottom: 24px;

        .col-12 {
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: flex-start;
        }
    }

    .rich-text {
        margin-bottom: 0;
    }
}
