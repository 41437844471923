.content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
    }

    &__block {
        border: 1px solid $grey-30;
        padding: 48px 24px 24px;
        transition: all .3s !important;

        &:hover {
            background-color: #fbfbfb;
        }

        &:hover .content-grid__link {
            color: #3f1bff;
        }

        &:hover .content-grid__link .arrow {
            stroke: #3f1bff;
        }
    }

    &__title {
        font-size: rem(14);
        font-weight: 500;
        color: $grey-120;

        &--lg {
            font-size: rem(20);
            line-height: rem(25);
            font-weight: 300;
            margin-bottom: 8px;
            color: $grey-120;
        }
    }

    &__text {
        font-size: rem(14);
        font-weight: 300;
        color: $grey-120;
        display: block;
        margin-bottom: 24px;

        &:hover {
            color: $violet-90;
        }
    }

    &__link {
        color: $grey-80;
        display: block;
        text-align: right;
        font-size: rem(24);
        font-weight: 700;
    }
}

.title-primary,
.title-primary h2 {
    font-size: rem(32);
    line-height: rem(44);
    font-weight: 300;
    color: $grey-120;
    padding-bottom: rem(32);

    @media (max-width: 575px) {
        font-size: rem(24);
    }
}

.title-secondary {
    font-size: rem(24);
    font-weight: 300;
    color: $grey-120;
    padding-bottom: 24px;
}

.insight-and-research-home {
    .content-grid {
        position: relative;

        & .right-line {
            position: absolute;
            background-color: $grey-30;
            display: block;
            width: 25px;
            height: 1px;
            left: -25px;
            top: 10px;
            z-index: -1;
            @media (max-width: 767px) {
                display: none;
            }
        }

        & .left-line {
            position: absolute;
            background-color: $grey-30;
            display: block;
            width: 25px;
            height: 1px;
            right: -25px;
            top: 10px;
            z-index: -1;
            @media (max-width: 767px) {
                display: none;
            }
        }
    }
}
