.case-project-info {
    padding: 64px 0;
    @media (max-width: 575px) {
        padding-top: 30px;
        .mb-30px {
            margin-bottom: 15px;
        }
    }
}

.case-study-image-container {
    width: 100%;
    height: 100%;
}

.case-project-quote {
    background-color: $grey-10;
    padding: 24px 16px 16px;
    position: relative;
    margin-bottom: 48px;

    &__icon {
        height: 30px;
        left: 16px;
        position: absolute;
        top: -15px;
        width: 40px;
    }

    &__author {
        font-family: $font-title;
        font-size: rem(16);
        font-weight: $fw-600;
        color: $grey-90;
        padding-bottom: 0;
    }
}

.technology-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.technology-item {
    background-color: $white;
    border: 1px solid $grey-30;
    color: $violet-90;
    display: inline-block;
    margin-bottom: 0;
    padding: 4px 12px;
    align-items: center;
}

#case-study-swiper {
    .swiper-wrapper {
        @media (min-width: 992px) {
            gap: 24px;
        }
    }
}

.case-study__img {
   width: 100%;
}

.case-study-awards {
    padding: 48px 0;

    ul {
        padding: 0;
        list-style: none;

        li {
            font-size: rem(16);
            line-height: rem(24);
            padding: 0;
            margin-bottom: 16px;
            font-weight: $fw-300;
        }
    }
}
