/* COLORS
===========================
Important: Always define primary color
*/

$color-primary: #EE3A51;
$color-secondary: #5236FF;
//$color-extra:  #00ff00; (Use or remove)

$white: #FFF;
$dark-white: #F6F6F6;
$grey-light: #e2e4e7;
$grey-dark: #262525;
$grey: #b1b3b7;
$grey-120: #111112;
$grey-110: #515157;
$grey-100: #6F6F77;
$grey-90: #7A7A83;
$grey-80: #ADADB6;
$grey-60: #D0D0D6;
$grey-40: #E2E2E7;
$grey-30: #ECECF0;
$grey-20: #F7F7FA;
$grey-10:#FBFBFC;

$black: #000;
$violet-10: #FCFCFF;
$violet-30: #EAEDFF;
$violet-90: #3F0AFF;
$violet-50: #CAD2FF;
$violet-90: #3F0AFF;
$violet-80: #7C84FF;
$violet-110: #240BA5;

/* SOCIAL MEDIA */

$facebook-color: #3b5999;
$twitter-color: #55acee;
$linkedin-color: #0077b5;
$youtube-color: #cd201f;
$pinterest-color: #cb2026;
$googleplus-color: #dd5044;
$instagram-color: #e4405f;


/* TEXT COLORS
=========================== */

.text-primary { color: $color-primary !important }
.text-white { color: $white !important }
.text-grey-light { color: $grey-light !important }
.text-grey { color: $grey !important }
.text-grey-dark { color: $grey-dark !important }
.text-black { color: $black !important }


/* BACKGROUND COLORS
=========================== */

.bg-primary { background-color: $color-primary !important }
.bg-white { background-color: $white !important }
.bg-dark-white { background-color: $dark-white !important }
.bg-grey-light { background-color: $grey-light !important }
.bg-grey { background-color: $grey !important }
.bg-grey-dark { background-color: $grey-dark !important }
.bg-black { background-color: $black !important }

.bg-lg-grey-dark {
    @media screen and (min-width: 992px) {
        background-color: $grey-dark !important
    }
}
