/** career_page **/
.careers-info {
    padding-bottom: 50px;

    .life-genius-section {
        padding: 60px 15px 0 15px;

        @media (min-width: 992px) {
            padding: 60px 60px 0 15px;
        }

        h3 {
            margin-bottom: 30px;
        }

        .step {
            display: flex;
            align-items: center;
            padding-left: 0;
            gap: 5px;

            .step-number {
                position: initial;
            }
        }

        ul {
            list-style: initial;
            margin-left: 15px;
            line-height: 30px;
        }

        .text-content {
            margin-bottom: 30px;
        }
    }

    .contact-application {
        @media (min-width: 992px) {
            margin-top: 50px;
        }
    }

    .open-positions {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        position: relative;

        .open-positions-items {
            flex-grow: 1;
            flex-shrink: 1;

            .positions-title {
                color: $grey-dark;
            }

            p {
                color: $grey-dark;
            }

            .content-grid {
                position: relative;

                &::after {
                    content: '';
                    background-color: $grey-30;
                    display: block;
                    max-width: 90vw;
                    height: 1px;
                    position: absolute;
                    left: -30px;
                    right: -30px;
                    bottom: 0;
                    z-index: -1;
                }
            }
        }
    }

    @include breakpoint(lg) {
        .open-positions-items {
            .positions-title {
                color: $grey-120;
            }

            p {
                color: $grey-120;
            }
        }
    }
    @include breakpoint(xl) {
        .open-positions {
            padding-top: 0;
            background-color: initial;
        }
    }
}

.nx-career-widgets {
    .rich-text {
        li {
            color: $grey-110;

            b, strong {
                color: $grey-120;
            }
        }
    }
}
