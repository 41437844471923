.logos-marquee {
    display: flex;
    overflow: hidden;
    margin-bottom: 70px;

    .image-wrapper {
        display: flex;
        align-items: center;

        & img {
            display: inline;
            object-fit: contain;
            max-width: 100%;
            margin-right: 50px;
        }
    }
}
