.solutions-wrap {
    padding-bottom: 50px;

    @media (max-width: 820px) {
        padding-top: 30px;
    }
    @media (min-width: 992px) {
        .solutions-description {
            padding: 60px 60px 0 15px;
        }
    }
}

.solutions-info-item {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    a {
        color: $color-secondary;
        cursor: pointer;
    }
    .solutions-icons {
        padding: 5px 10px 0 0;
        font-size: 16px;
        color: $color-secondary;
        display: flex;
        align-self: self-start;
    }
}

.case-study-txt {
    .rich-text {
        font-size: rem(14);
    }
}

.case-study-preview {
    border-radius: 8px;
    width: 100%;
    height: auto;
}

