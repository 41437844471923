/* BUTTONS
===========================
Important:
- Place button variations in btn mixin
- You should be able to combine all variants without any issue
 */

a, .bttn, button, .solutions-link {
    text-decoration: none;
    @include transition-sm();
    &:focus, &:active, &:hover {
       // outline: none !important;
        text-decoration: none;
    }

}

.bttn-default, .bttn {
    font-family: $font-content;
    font-size: .9rem;
    font-weight: 500;
    padding: 7px 10px;
    border-radius: 5px;
    text-align: center;
    @include breakpoint(lg){
        font-size: 1rem;
    }

    @include breakpoint(sm){
        padding: 10px 20px;
        font-size: 14px;
    }
}

@mixin bttn ($background, $color) {
    @extend .bttn-default;
    background-color: $background;
    color: $color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover, &:focus, &:active, &:active:focus, &:active:hover {
        color: $color;
        box-shadow: none;
    }
    &.bttn-sm {
        padding: 2px 40px;
    }
}

.bttn-primary {
    @include bttn($color-secondary, $white!important);
    border: 1px solid $color-secondary;
    position: relative;
    &:hover {
        transform: scale(0.97);
    }
}

.bttn-black {
    @include bttn(white, $black);
    border: 1px solid $black;
    position: relative;
    &:hover {
        transform: scale(0.97);
    }
}

.solution-button {
    cursor: pointer;
    min-width: 110px;
}

.bttn-white {
    @include bttn(#fff, $violet-90);
    border: 1px solid $violet-30;
    position: relative;
    &:hover {
        transform: scale(0.97);
    }
}

.bttn-red {
    @include bttn(#ef3b55, $white);
    border: 1px solid $white;
    box-shadow: initial;
    background-color: #ef3b55;
    &:hover, &:focus, &:active, &:active:focus, &:active:hover {
        background-color: #FFFFFF;
        color: #ef3b55;

    }
}

.bttn-white-red {
    @extend .bttn-white;
    @media (max-width: 800px) {
        border: 1px solid $white;
        box-shadow: initial;
        background-color:  #ef3b55;
        &:hover, &:focus, &:active, &:active:focus, &:active:hover {
            background-color: #FFFFFF;
            color: #ef3b55;

        }
    }
}

.bttn-white-dark-grey {
    @extend .bttn-white;
    @media (max-width: 800px) {
        border: 1px solid $white;
        box-shadow: initial;
        background-color:  #3c3b3b;
        &:hover, &:focus, &:active, &:active:focus, &:active:hover {
            background-color: #FFFFFF;
            color: #3c3b3b;

        }
    }
}

.bttn-white-fill {
    @include bttn($white, $violet-90);
    position: relative;
    border: 1px solid $white;
    display: inline-block;
    &:hover {
        transform: scale(0.97);
    }
}

.bttn-full {
    width: 100%;
}

.btn {
    &--filters-toggle {
        align-items: center;
        border: 1px solid $grey-30;
        display: inline-flex;
        gap: 8px;
        justify-content: center;
        margin-bottom: 16px;
        padding: 12px 16px;
        color: $grey-120;
        font-size: rem(12);
        text-transform: uppercase;
        margin-top: 48px;

        &:hover, &:focus, &:active {
            color: $grey-120;
        }

        &:not(.collapsed) {
            background-color: $grey-10;
            img {
                width: 16px;
                height: 16px;
                transform: rotate(180deg);
            }
        }
    }
}

*, button, a, input, select, textarea {
    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: 2px solid $violet-90;
        outline-offset: 2px;
    }
}
