.text-image-container {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 400px);
    gap: 32px;
    align-items: center;
    margin: 70px auto;

    @media (max-width: 767px) {
        grid-template-columns: 1fr !important;
    }

    &.image-left {
        grid-template-columns: minmax(auto, 400px) 1fr; /* Swap columns */

        & .text-container {
            order: 2;
        }

        & .image-container {
            order: 1;
        }

    }

    &.image-right {
        grid-template-columns: 1fr minmax(auto, 400px); /* Default */

        & .text-container {
            order: 1;
        }

        & .image-container {
            order: 2;
        }
    }

    & .text-container {
        flex: 2;

        & h2 {
            color: $grey-120;
            font-weight: 300;
            @media (max-width: 767px) {
                font-size: rem(22);
            }
        }
    }

    & .image-container {
        flex: 1;

        & img {
            width: 100%;
        }
    }
}
