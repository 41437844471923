.solution-info-heading {
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
}

.solutions-info-item {
    ul {
        li {
            margin-bottom: 19px;
        }
    }
}

.solutions-description {
    .case-study-section {
        margin-top: 64px;
    }

    ol {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
        margin: 24px 0 32px;

        li {
            border: 1px solid $grey-30;
            font-size: 0.875rem;
            display: block;
            flex: 1 1 25%;
            gap: 16px;
            width: 25%;
            position: relative;
            padding: 48px 24px 58px !important;
            margin: 0;

            sub {
                color: $grey-80;
                font-size: 1.25rem;
                font-weight: 700;
                position: absolute;
                right: 24px;
                bottom: 24px;
            }

            @media (max-width: 1199px) {
                flex: 1 1 50%;
                width: 50%;
            }

            @media (max-width: 767px) {
                flex: 1 1 100%;
                width: 100%;
            }
        }
    }
}

.landing .solutions-description {
    & h1 {
        margin-bottom: 0 !important;
    }
}
