/* RESPONSIVE
===========================
*/
/* breakpoints based on bootstrap 4 */
$screen-xxxl: 1680px; // Desktop
$screen-xxl: 1360px; // HD Laptop
$screen-xl: 1200px; // Extra large devices (large desktops, 1200px and up)
$screen-lg: 992px; // Large devices (desktops, 992px and up)
$screen-md: 768px; // Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint
$screen-sm: 576px; // Small devices (landscape phones, 576px and up)
$screen-xs: 450px; // XSmall devices
$screen-xxs: 260px; // XXSmall devices

@mixin breakpoint($class) {
    @if $class == xxs {
        @media (min-width: $screen-xxs) {
            @content;
        }
    } @else if $class == xs {
        @media (min-width: $screen-xs) {
            @content;
        }
    } @else if $class == sm {
        @media (min-width: $screen-sm) {
            @content;
        }
    } @else if $class == md {
        @media (min-width: $screen-md) {
            @content;
        }
    } @else if $class == lg {
        @media (min-width: $screen-lg) {
            @content;
        }
    } @else if $class == xl {
        @media (min-width: $screen-xl) {
            @content;
        }
    } @else if $class == xxl {
        @media (min-width: $screen-xxl) {
            @content;
        }
    } @else if $class == xxxl {
        @media (min-width: $screen-xxxl) {
            @content;
        }
    } @else {
        @warn "Breakpoint mixin supports: sm, md, lg, xl, xxl, xxxl";
    }
}

.show-lg {
    display: flex !important;
    @include breakpoint(lg) {
        display: none !important;
    }
}

.hide-lg {
    display: none !important;
    @include breakpoint(lg) {
        display: flex !important;
    }
}

.hide-xl {
    display: none;
    @include breakpoint(xl) {
        display: flex;
    }
}

.hide-sm{
    display: none !important;
    @include breakpoint(sm){
        display: flex !important;
    }
}

.hide-md{
    display: none !important;
    @include breakpoint(md){
        display: flex !important;
    }
}

.margin-bottom-resp{
    margin-bottom: 15px;
    @include breakpoint(lg){
        margin-bottom: 0;
    }
}

