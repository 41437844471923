.case-study-carousel {
    display: flex;
    gap: 32px;
    margin-bottom: 70px;
    @media (max-width: 991px) {
        flex-wrap: wrap;
    }

    &__left {
        position: relative;
        border: 1px solid #E2E2E7;
        @media (max-width: 991px) {
            width: 100%;
        }

        & .case-study-card {
            width: 340px;
            padding: 40px 28px !important;
            @media (max-width: 991px) {
                width: 100%;
            }

            & .sub-title {
                color: #7A7A83;
                font-size: rem(16);
                font-weight: 500;
                margin-bottom: 10px;
            }
        }

        & .swiper-navigation {
            position: absolute;
            bottom: 20px;
            z-index: 1;
            left: 30px;
            @media (max-width: 991px) {
                bottom: 2px;
            }
        }
    }

    &__right {
        width: 100%;

        & .case-study-card {

            & img {
                width: 100%;
                object-fit: cover;
                object-position: top;
                height: 500px;
                display: inline-block;
                margin-bottom: 32px;
                @media (max-width: 991px) {
                    height: auto;
                    object-fit: contain;
                }
            }

            & p {
                margin-bottom: 24px;
            }

            & a {
                display: inline-block;
                margin-bottom: 4px;
            }
        }
    }
}
