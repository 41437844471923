.expertise-grid {
    border-left: 1px solid $grey-30;
    text-align: center;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    padding: 0;
    margin: 0 auto;

    &::after {
        content: '';
        background-color: $grey-30;
        display: block;
        width: 25px;
        height: 2px;
        position: absolute;
        top: var(--after-top, calc(50% - 1px));
        left: -25px;
        z-index: -1;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &::before {
        content: '';
        background-color: $grey-30;
        display: block;
        width: 25px;
        height: 2px;
        position: absolute;
        top: var(--after-top, calc(50% - 1px));
        right: -25px;
        z-index: -1;
        @media (max-width: 767px) {
            display: none;
        }
    }

    @media (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);

        &::after {
            display: none;
        }
    }
}

.expertise-grid a {
    background-color: $white;
    border: 1px solid $grey-30;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    position: relative;

    &:hover {
        background-color: #fbfbfb;
    }

    h3 {
        padding: 32px 10px;
        color: $grey-120;
        font-weight: 300;
        font-size: 20px;
        margin-bottom: 0;

        @media (max-width: 992px) {
            font-size: 18px;
        }

        @media (max-width: 768px) {
            font-size: 16px;
        }

        @media (max-width: 450px) {
            font-size: 12px;
        }
    }
}
