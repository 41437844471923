.single-blog {
    padding-top: 48px;
    padding-bottom: 48px;

    &__right {
        border-left: 1px solid $grey-40;
        padding-left: rem(30);

        @media(max-width: 767px) {
            border-left: none;
            padding-left: 15px;
        }
    }

    &__left {
        @media(max-width: 767px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
        }
    }

    &__return-btn {
        display: flex;
        gap: 4px;
        align-items: center;
        color: $grey-90;
        font-size: 16px;
        font-weight: 700;

        &:hover {
            color: $grey-90;
        }

        &:focus {
            color: $grey-90;
        }
    }

    &__social-media {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        font-weight: 300;
        margin-left: 27px;

        @media(max-width: 767px) {
            margin-top: 0;
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }

        &--title {
            color: $grey-90;
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 16px;

            @media(max-width: 767px) {
                order: 1;
                margin: 0;
            }
        }

        &--icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            color: white;
            background-color: $violet-80;
            margin-bottom: 6px;

            &:hover {
                color: white;
                opacity: 0.7;
            }
        }

    }

    &__social-icons {
        @media(max-width: 767px) {
            position: absolute;
            display: none;
            top: 60px;
            right: 0;
            z-index: 999;
        }
    }

    &__social-media-mobile-btn {
        display: none;
        background-color: $violet-80;
        height: 48px;
        width: 48px;
        border-radius: 50%;

        @media(max-width: 767px) {
            display: flex;
            align-items: center;
            justify-content: center;
            order: 2;
        }
    }

    &__author {
        color: $violet-90;
        font-size: rem(16);
    }

    &__date {
        color: $grey-120;
        font-size: rem(14) !important;
        font-weight: 400;
        margin-bottom: rem(24);
    }
}

.blog-info-bottom-section {
    background-color: #fff;
    color: $grey-dark;
    padding: 25px 100px;
    box-shadow: 0 10px 35px -10px rgba(0, 0, 0, 0.3);
    letter-spacing: 0.5px;
    transition: all 0.6s ease;
    border-radius: 10px;
    transform: translate3d(0, 0, 0);

    &:hover {
        box-shadow: 0 10px 65px -10px rgba(0, 0, 0, 0.75);
    }

    @media (max-width: $screen-md) {
        padding: 25px;
    }
}

.article-author {
    text-decoration: none !important;
    margin-bottom: 15px;
    margin-top: 15px;
    display: flex;

    .article-author-details {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .article-author-name {
            display: block;
            color: #000;
            font-size: 12px;
            font-weight: 700;
            transition: all 0.3s ease;
        }

        .article-author-date {
            display: block;
            color: #d9d9d9;
            font-size: 12px;
            font-weight: 700;
            margin-left: 0;
        }
    }
}
.text-color {
    padding: 0 2px;

    &.violet {
        color: $color-secondary;
    }
}

.blog-item-content, .flex-page-block-container {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .article-list-h1,
    .article-list-h2 {
        font-family: Poppins, sans-serif;
        text-transform: initial;
        font-weight: 300;
    }

    blockquote {
        background: #f7f7f7;
        border-left: 4px solid #ccc;
        padding: 0.5em 20px;
    }
    input[type="image"] {
        height: auto !important;
        max-width: 100%;

        @media (max-width: 575px) {
            color: $grey-dark !important;
            margin: initial !important;
        }
    }
}

.template-detail-article-page, .flex-page-block-container {
    .article-category {
       display: inline-block;
       margin-bottom: 2rem;
    }
    p, li, blockquote, a {
        font-size: 16px;
    }
}
