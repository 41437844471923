.contact-content {

    .rich-text {
        padding-top: 64px;
        padding-bottom: 48px;

        @media (max-width: 767px) {
            padding-top: 48px;
            padding-bottom: 32px;
        }

        @media (max-width: 550px) {
            padding-top: 32px;
            padding-bottom: 24px;
        }
    }
}

.form-container {
    background-color: $grey-20;
    padding: 48px 0;
}
