/* main header */

@keyframes logo-move {
    0% {
        top: -6px;
        left: 38.5px;
    }
    50% {
        top: -6px;
        left: 53px;
    }
    100% {
        top: -6px;
        left: 38.5px;
    }
}

.main-header {
    background-color: transparent;
    width: 100%;
    position: fixed;
    z-index: 100;

    .logo-main {
        display: flex;
        align-items: center;
        position: relative;

        &::after {
            content: "\00AE";
            font-size: 0.875rem;
            font-weight: 200;
            color: $grey-120;
            display: block;
            position: absolute;
            top: 0;
            right: -0.875rem;
        }

        & a {
            display: flex;
            align-items: center;
        }

        .logo-img {
            height: 56px;
        }

        .logo-txt {
            font-family: Poppins, sans-serif;
            font-size: 13px;
            margin-bottom: 0;
            line-height: unset;
            font-weight: 200;
        }
    }

    .nav-main {
        display: flex;
        align-items: center;
        height: 100%;

        .nav-submenu {
            align-items: flex-start;
            border-top: 1px solid $grey-30;
            border-bottom: 1px solid $grey-30;
            display: none;
            flex-direction: column;
            justify-content: flex-start;
            left: 0;
            list-style: none;
            position: absolute;
            top: 100%;
            width: 100%;
        }

        .nav-list {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            list-style: none;
            margin-bottom: 0;
            padding-left: initial;
            gap: 16px;

            &--level-2 {
                list-style: none;
                padding: 0;
            }

            &--level-3 {
                justify-content: flex-start;
                align-items: flex-start;
                gap: 8px;
            }

            &--level-4 {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 0;
            }

            li {
                padding-bottom: initial;
                margin-bottom: initial;
            }

            .nav-item {
                font-size: 14px;
                font-family: 'Poppins', sans-serif;
                padding-left: 0;
                padding-right: 0;

                &--level-2 {
                    &:not(:first-child) {
                        display: none;
                    }
                }

                .nav-link--level-1 {
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: inherit;
                        transition: all 0.2s ease;
                        z-index: -1;
                    }
                }

                .nav-link, .language_style {
                    padding: 6px 12px;
                    background: initial;
                    border: 0;
                    position: relative;
                    z-index: 1;

                    &:hover span {
                        color: $violet-90;
                    }

                    & .link-title {
                        position: absolute !important;
                        top: 50%;
                        right: 50%;
                        transform: translate(50%, -50%);
                        z-index: 9;
                        transition: all 0.2s ease;

                        &.link-title-careers {
                            right: 62%;
                        }

                        &.link-title-img {
                            right: 62%;
                        }

                    }

                    &:hover::before {
                        filter: blur(5px);
                        background-color: #dbe0ff;
                    }

                    &.open-submenu {
                        &.nav-link--level-1 {
                            &::before {
                                filter: blur(5px);
                                background-color: #dbe0ff;
                            }
                        }

                        & span {
                            color: $violet-90;
                        }

                        .nav-link-icon {
                            transform: rotate(0deg);
                            margin-bottom: 2px;
                        }
                    }

                    span {
                        padding: 0;
                        position: relative;
                        color: $grey-120;

                        &:hover {
                            color: $violet-90;
                        }
                    }

                    .nav-link-careers-count {
                        border-radius: 2px;
                        background-color: $violet-90;
                        color: $white !important;
                        font-size: 10px;
                        font-weight: 600;
                        padding: 2px 6px;

                    }
                }

                .nav-link {
                    &-icon {
                        width: 14px;
                        height: 8px;
                        transform: rotate(180deg);
                        margin-left: 2px;
                        transition: all 0.3s linear;

                        &--level-2 {
                            width: 32px;
                            height: 32px;
                            transform: rotate(90deg);
                            margin-left: 0;
                        }
                    }

                    &--level-2 {
                        align-items: center;
                        display: flex;
                        flex-direction: row;
                        font-size: rem(16);
                        font-weight: $fw-400;
                        gap: 8px;
                        justify-content: flex-start;
                        padding: 8px 16px;
                        width: 100%;

                        span {
                            text-align: left;
                        }
                    }

                    &--level-3 {
                        padding: 8px 16px;
                        font-size: rem(16);
                        line-height: rem(24);
                        font-weight: $fw-400;
                    }

                    &--level-4 {
                        font-family: $font-content;
                        font-size: rem(16);
                        font-weight: $fw-300;
                        line-height: rem(24);
                        padding: 8px 16px;
                    }
                }

                span.nav-link {
                    &:hover {
                        background-color: initial;
                    }
                }
            }

            .nav-go {
                border: 1px solid $white;
                border-radius: 4px;
                padding: 2px 13px;
            }

            @include breakpoint(sm) {
                padding-left: 0;
            }
        }
    }

    .nav-mobile-open {
        border: initial;
        background: initial;
        margin-bottom: 2px;

        &--icon {
            width: 20px;
            height: 16px;
        }

        input {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            cursor: pointer;
            opacity: 0;
            z-index: 2;
            -webkit-touch-callout: none;

            &:checked {
                ~ span {
                    opacity: 1;
                    transform: rotate(45deg) translate(0, -18px);
                }

                ~ span:nth-last-child(3) {
                    opacity: 0;
                    transform: rotate(0deg) scale(0.2, 0.2);
                }

                ~ span:nth-last-child(2) {
                    transform: rotate(-45deg) translate(0, 20px);
                }
            }
        }

        span {
            display: block;
            width: 30px;
            height: 2px;
            margin-bottom: 7px;
            position: relative;
            background: $grey-120;
            z-index: 1;
            transform-origin: 4px 0;
            transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1);

            &:first-child {
                transform-origin: 0 0;
            }

            &:nth-last-child(2) {
                transform-origin: 0 100%;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .nav-mobile-close {
        position: absolute;
        top: 34px;
        right: 18px;
        background-color: initial;
        border: initial;
        padding: 4px;

        &-icon {
            width: 18px;
            height: 18px;
        }
    }

    .nav-mobile-open, .nav-mobile-close {
        &:focus {
            outline: none;
        }

        &:focus-visible {
            outline: 2px solid #3f0aff;
            outline-offset: 2px;
        }
    }

    /* main header solution panel dropdown menu items */

    .solutions-panel {
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        padding-top: 60px;
        background-color: $white;
        z-index: 120;
        padding-bottom: 25px;
        border-bottom: 1px solid $grey-30;

        .container {
            @media (max-width: 991px) {
                max-width: initial;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .tools-titles {
            font-family: $font-title;
            font-size: 18px;
            color: $grey-120;
        }

        .tools-logos {
            .tools-logo {
                display: flex;
                justify-content: center;
                margin-bottom: 65px;

                img {
                    height: 38px;
                }
            }
        }

        .tool-name {
            font-family: $font-title;
            font-weight: 700;
            font-size: 12px;
            color: $white;
            margin-bottom: 15px;
        }

        .tool-detail,
        .tool-detail-alt,
        .tool-detail-alt2 {
            font-family: $font-title;
            font-weight: 100;
            font-size: 12px;
            color: $white;
        }

        .tool-detail {
            margin-bottom: 14px;
            padding-left: 15px;
        }

        .tool-detail-alt {
            margin-bottom: 22px;
        }

        .tool-detail-alt2 {
            margin-bottom: 30px;
        }

        a.hover-animation {
            position: relative;
            display: inline-block;
        }

        .top-menu-sub-items {
            width: 100%;

            .icon-niche {
                width: 50px;
                vertical-align: top;
                padding-top: 5px;

                img {
                    max-width: 40px;
                    max-height: 40px;
                }
            }

            .items-list {
                margin: 0 0 5px 0;

                a {
                    color: $grey-120;
                    padding: 4px 0;

                    &:hover {
                        background-color: $violet-30;
                        padding: 4px 8px;
                    }
                }

                a[href="#"] {
                    color: #a1a1a1;
                    cursor: default;
                    line-height: 2;

                    &:hover {
                        color: #a1a1a1 !important;
                    }
                }
            }
        }

        .top-menu-items-coll {
            hr {
                border-top: transparent;
            }
        }

        .top-menu-items-coll:nth-child(1) {
            .items-list p:nth-child(1) {
                font-weight: bold;
                margin-bottom: 10px;
            }
        }

        .solutions-panel-row {
            color: $grey-120;
            max-height: 80vh;

            p {
                //margin-bottom: 1rem !important;
                padding-bottom: initial;
            }
        }

        .solutions-panel-top-section {
            padding-top: 10px;
            margin-bottom: 32px;
            @media (min-width: 992px) {
                border-bottom: 1px solid $grey-30;
            }
        }

        @media (min-width: 992px) {
            top: -140px;
            z-index: 110;
            position: relative;
        }
        @media (max-width: 991px) {
            top: -50px;
            z-index: 110;
            padding-top: 10px;
            position: fixed;
            > .container {
                padding: 0;
            }
            .solutions-panel-top-section {
                width: 100%;
                padding-right: 30px;
                position: fixed;
                z-index: 2;
                height: 45px;
                left: 0;
            }
            .close-style.close-text {
                display: none;
            }
            .row.tools-titles {
                .col-4 {
                    display: none;
                }
            }
            .solutions-panel-row {
                margin-top: 70px;
                padding: 0 15px;
                overflow-y: auto;

                .top-menu-items-coll {
                    max-width: 100%;
                    padding: 0 35px;
                }
            }
            .top-menu-sub-items {
                .icon-niche {
                    width: 35px;

                    img {
                        max-width: 30px;
                    }
                }
            }
        }
    }

    .show-menu {
        display: block !important;
    }

    .show-only-mob {
        @media (min-width: 992px) {
            display: none !important;
        }
    }

    .solutions-panel-alt {
        overflow: scroll;
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        top: 74px;
        background-color: $grey-dark;
        z-index: 1000;
        margin-top: 50px;

        .tools-titles {
            font-family: $font-title;
            font-size: 18px;
            color: $white;
            font-weight: 700;
        }

        .tools-logos {
            .tools-logo {
                display: flex;
                justify-content: center;
                margin-bottom: 65px;

                img {
                    height: 38px;
                }
            }
        }

        .tool-name {
            font-family: $font-title;
            font-weight: 700;
            font-size: 12px;
            color: $white;
            margin-bottom: 15px;
        }

        .tool-detail,
        .tool-detail-alt,
        .tool-detail-alt2 {
            font-family: $font-title;
            font-weight: 100;
            font-size: 12px;
            color: $white;
        }

        .tool-detail {
            margin-bottom: 14px;
            padding-left: 15px;
        }

        .tool-detail-alt {
            margin-bottom: 22px;
        }

        .tool-detail-alt2 {
            margin-bottom: 30px;
        }
    }

    .close-style {
        border: initial;
        background: none;
        padding: 4px;

        img {
            width: 16px;
            height: 16px;
        }
    }

    .close-text {
        position: absolute;
        top: 28px;
        font-weight: 200;
        font-size: 12px;
        width: 130px;
    }

    .header-solution-menu-close {
        cursor: pointer;
    }

    /* header hamburger side panel menu items*/
    .nav-mobile {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        max-width: 575px;
        height: 100vh;
        z-index: 111;
        padding: 95px 0 0 0;
        margin-bottom: 0;
        background: $white;
        backdrop-filter: blur(5px);
        list-style-type: none;
        transform-origin: 0 0;
        transition: transform 0.2s linear;
        transform: translate(100%, 0px);
        border-left: 1px solid $grey-30;

        &__list {
            padding: 0;
            list-style: none;

            @media (max-width: 575px) {
                overflow: auto;
                height: 100%;
            }

            &--level-2 {
                display: none;
            }


            &--level-3 {
                display: none;
            }
        }

        &__item {
            display: block;
            padding-left: 0;
        }

        &__link {
            background: initial;
            border: 0;
            color: $grey-120;
            display: block;
            font-family: "Poppins", sans-serif;
            font-size: rem(32);
            font-weight: $fw-300;
            height: 100%;
            line-height: normal;
            padding: 16px 64px;
            position: relative;
            text-align: left;
            text-decoration: none;
            transition: background-color 0.3s ease;
            width: 100%;

            &-icon {
                width: 32px;
                height: 32px;
                transform: rotate(90deg);
            }

            &.open-dropdown {
                color: $violet-90;

                img {
                    transform: rotate(0deg);
                }
            }

            @media (max-width: 575px) {
                padding: 16px 32px;
                font-size: rem(24);
            }

            &--level-2 {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;
                width: 100%;
                max-width: 446px;
                margin: 0 auto;
                font-size: rem(20);
                line-height: 125%;
                padding: 16px;
                @media (max-width: 575px) {
                    max-width: calc(100% - 64px);
                    margin: 0 32px;
                }
            }

            &--level-3 {
                font-size: rem(20);
                line-height: 125%;
                padding: 16px;
                max-width: 414px;
                margin: 0 auto;
                @media (max-width: 575px) {
                    max-width: calc(100% - 64px);
                    margin: 0 32px;
                }
            }

            &--level-4 {
                font-size: rem(16);
                font-family: $font-content;
                line-height: rem(24);
                padding: 0 16px;
                max-width: 414px;
                margin: 0 auto;

                @media (max-width: 575px) {
                    max-width: calc(100% - 64px);
                    margin: 0 32px;
                }
            }
        }
    }
}

.menu-open {
    transform: none !important;
}

.show-solutions-panel-menu {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 8px;
}

.border-right {
    border-right: 1px solid $grey-30;
}

.landing-language_style {
    list-style: none;
    font-size: 14px;
    color: $grey-120;
}
