.banner {
    position: relative;
    padding: 96px 0;
    background-image: url("../images/banner_bg.png");
    background-size: cover;
    position: relative;
    text-align: center;
    color: $white;

    &:not(:last-child) {
        margin-bottom: 70px;
    }

    & .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--banner-background);
        opacity: .7;
        z-index: 0;
    }

    & .content-wrapper {
        position: relative;
        z-index: 2;

        & form {
            text-align: left;
        }
    }

    & a {
        position: relative;
        z-index: 2;
        display: inline-block;
        margin-top: 36px;
    }

    & h2 {
        position: relative;
        z-index: 2;
        color: $white;
    }

    .banner__text p {
        position: relative;
        z-index: 2;
        color: $white;
        font-size: rem(18);
        font-weight: 300;
    }
}
