#notfound {
    color: $grey-120;
    font-family: Poppins, sans-serif;

    .error-logo-nixa {
        width: 75px;
        margin-bottom: initial;
    }

    h1 {
        font-size: 36px;
        line-height: 44px;
        padding-top: 32px;

        @media (max-width: 575px) {
            margin-top: 95px;
        }
    }

    p {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0;
    }
}
