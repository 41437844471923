.hero-container {
    max-width: 1430px;
    padding: 0 15px;
    margin: 0 auto;
    @media (max-width: 767px) {
        padding: 0;
    }

    & .single-image-container {
        max-width: 100%;
        @media (max-width: 767px) {
            height: auto;
        }

        & .rich-text {
            margin-bottom: 15px;
        }

        & .rich-text h1 {
            padding: 0;
            line-height: 56px;
            margin-top: -11px;
            font-size: 2.25rem;
            @media (max-width: 1176px) {
                font-size: 1.75rem;
                line-height: 46px;
            }

            @media (max-width: 935px) {
                font-size: 1.6rem;
                line-height: 40px;
            }

            @media (max-width: 801px) {
                font-size: 1.6rem;
                line-height: 36px;
            }

        }
    }

    & .slide-left {
        max-height: 100%;
    }

    & .slide-right {
        max-height: 100%;

        & .home-hero-image {
            max-height: 100%;
        }

        & .hero-image-multi {
            border-radius: 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            position: relative;
            padding-right: 40px;

            @media (max-width: 991px) {
                padding-right: 0;
            }

            & .image-container {
                overflow: hidden;
                border-radius: 10px;
            }

            & .image-bottom-container {
                overflow: hidden;
                border-radius: 10px;
                position: relative;

                & .icons-container {
                    position: absolute;
                    top: 14px;
                    left: 19px;
                    display: flex;
                    align-items: center;
                    gap: 14px;
                    @media (max-width: 767px) {
                        gap: 10px;
                    }

                    & img {
                        height: 30px;
                        width: 30px;
                    }


                }
            }

            & .badge-image-container {
                position: relative;

                & .hero-badge {
                    position: absolute;
                    height: 50px;
                    width: 50px;
                    top: -7px;
                    right: -10px;
                    z-index: 2;

                    @media (max-width: 991px) {
                        height: 40px;
                        width: 40px;
                    }
                    @media (max-width: 767px) {
                        height: 40px;
                        width: 40px;
                    }
                }

                & .title-on-image {
                    position: absolute;
                    top: 15px;
                    left: 17px;

                    @media (max-width: 991px) {
                        top: 10px;
                    }
                    @media (max-width: 767px) {
                        top: 10px;
                    }

                    & * {
                        color: #fff;
                        font-size: 24px;
                        font-weight: 700;

                        @media (max-width: 1100px) {
                            font-size: 20px;
                        }

                        @media (max-width: 991px) {
                            font-size: 18px;
                        }
                        @media (max-width: 767px) {
                            font-size: 16px;
                        }
                    }
                }

                & .description-on-image {
                    position: absolute;
                    top: 85px;
                    left: 17px;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 300;
                    @media (max-width: 1200px) {
                        top: 75px;
                    }

                    @media (max-width: 991px) {
                        top: 65px;
                        font-size: 12px;
                    }
                    @media (max-width: 767px) {
                        top: 55px;
                        font-size: 14px;
                    }
                }

            }

            & img {
                max-width: 100%;
            }
        }
    }
}

.simple-hero-banner {
    padding: rem(100) 0;
    overflow: hidden;
    position: relative;
    background-size: cover;

    &__content {
        position: relative;
    }

    &__title {
        color: $grey-120;
        font-size: rem(32);
        font-weight: 300;
        line-height: rem(40);
        margin-bottom: rem(16);
    }

    &__career {
        text-transform: uppercase;
        color: $grey-90;
        font-size: rem(16);
        font-weight: 500;
        margin-bottom: 8px;
    }
}

.background {
    background: linear-gradient(0deg, #ffffff 0%, #f6f4ff 100%);
    overflow: hidden;
    position: absolute;
    height: 900px;
    width: 110%;
    top: 0;
    right: 0;
    z-index: -1;
    margin-top: -50px;

}

@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}


.background span {
    width: 20vmin;
    height: 20vmin;
    border-radius: 20vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}


.background span:nth-child(0) {
    color: #cdb7ff;
    top: 14%;
    left: 87%;
    animation-duration: 85s;
    animation-delay: -74s;
    transform-origin: -22vw -4vh;
    box-shadow: -38vmin 0 5.059385915546267vmin currentColor;
}

.background span:nth-child(1) {
    color: #ffffff;
    top: 55%;
    left: 76%;
    animation-duration: 96s;
    animation-delay: -35s;
    transform-origin: -3vw -12vh;
    box-shadow: 38vmin 0 4.889544419357417vmin currentColor;
}

.background span:nth-child(2) {
    color: #f8e3ff;
    top: 6%;
    left: 75%;
    animation-duration: 18s;
    animation-delay: -65s;
    transform-origin: -6vw -16vh;
    box-shadow: -38vmin 0 4.758051667676196vmin currentColor;
}

.background span:nth-child(3) {
    color: #c8aefd;
    top: 16%;
    left: 42%;
    animation-duration: 48s;
    animation-delay: -90s;
    transform-origin: 5vw 1vh;
    box-shadow: -38vmin 0 4.833739443619755vmin currentColor;
}

.background span:nth-child(4) {
    color: #f5e5fd;
    top: 10%;
    left: 96%;
    animation-duration: 66s;
    animation-delay: -81s;
    transform-origin: 3vw 1vh;
    box-shadow: 38vmin 0 5.140672827286329vmin currentColor;
}

.background span:nth-child(5) {
    color: #cdb7ff;
    top: 5%;
    left: 100%;
    animation-duration: 37s;
    animation-delay: -76s;
    transform-origin: -20vw -21vh;
    box-shadow: 38vmin 0 5.538940405951729vmin currentColor;
}

.background span:nth-child(6) {
    color: #ffffff;
    top: 10%;
    left: 42%;
    animation-duration: 65s;
    animation-delay: -85s;
    transform-origin: 7vw -14vh;
    box-shadow: -38vmin 0 5.736433785314066vmin currentColor;
}

.background span:nth-child(7) {
    color: #ffffff;
    top: 10%;
    left: 17%;
    animation-duration: 52s;
    animation-delay: -16s;
    transform-origin: 24vw 9vh;
    box-shadow: -38vmin 0 5.731348950713832vmin currentColor;
}

.background span:nth-child(8) {
    color: #ffffff;
    top: 28%;
    left: 81%;
    animation-duration: 33s;
    animation-delay: -7s;
    transform-origin: 15vw 7vh;
    box-shadow: -38vmin 0 4.905177267265499vmin currentColor;
}

.background span:nth-child(9) {
    color: #ffffff;
    top: 74%;
    left: 80%;
    animation-duration: 36s;
    animation-delay: -59s;
    transform-origin: 9vw 2vh;
    box-shadow: 38vmin 0 5.152538094713355vmin currentColor;
}

.background span:nth-child(10) {
    color: #eae5ff;
    top: 72%;
    left: 86%;
    animation-duration: 22s;
    animation-delay: -4s;
    transform-origin: 11vw -21vh;
    box-shadow: 38vmin 0 5.083359472651175vmin currentColor;
}

.background span:nth-child(11) {
    color: #ffffff;
    top: 99%;
    left: 50%;
    animation-duration: 114s;
    animation-delay: -9s;
    transform-origin: 3vw 15vh;
    box-shadow: 38vmin 0 5.244338421043902vmin currentColor;
}

.background span:nth-child(12) {
    color: #ffffff;
    top: 14%;
    left: 93%;
    animation-duration: 45s;
    animation-delay: -50s;
    transform-origin: 15vw -3vh;
    box-shadow: 38vmin 0 5.148871514623504vmin currentColor;
}

.background span:nth-child(13) {
    color: #eae5ff;
    top: 61%;
    left: 47%;
    animation-duration: 115s;
    animation-delay: -62s;
    transform-origin: 9vw 10vh;
    box-shadow: 38vmin 0 5.596003515575181vmin currentColor;
}

.background span:nth-child(14) {
    color: #cdb7ff;
    top: 12%;
    left: 34%;
    animation-duration: 36s;
    animation-delay: -3s;
    transform-origin: 6vw 4vh;
    box-shadow: 38vmin 0 5.43737562098457vmin currentColor;
}

.background span:nth-child(15) {
    color: #ffffff;
    top: 27%;
    left: 31%;
    animation-duration: 74s;
    animation-delay: -9s;
    transform-origin: 20vw 14vh;
    box-shadow: 38vmin 0 5.136684567705618vmin currentColor;
}

.background span:nth-child(16) {
    color: #cdb7ff;
    top: 21%;
    left: 89%;
    animation-duration: 107s;
    animation-delay: -43s;
    transform-origin: 24vw -5vh;
    box-shadow: -38vmin 0 5.352606418009346vmin currentColor;
}

.background span:nth-child(17) {
    color: #ffffff;
    top: 88%;
    left: 1%;
    animation-duration: 109s;
    animation-delay: -63s;
    transform-origin: -12vw -21vh;
    box-shadow: 38vmin 0 5.441967679266202vmin currentColor;
}

.background span:nth-child(18) {
    color: #ffffff;
    top: 99%;
    left: 9%;
    animation-duration: 81s;
    animation-delay: -43s;
    transform-origin: -22vw 17vh;
    box-shadow: -38vmin 0 5.066273158555219vmin currentColor;
}

.background span:nth-child(19) {
    color: #eae5ff;
    top: 14%;
    left: 15%;
    animation-duration: 15s;
    animation-delay: -23s;
    transform-origin: -12vw -2vh;
    box-shadow: 38vmin 0 4.894585659235787vmin currentColor;
}

.background span:nth-child(20) {
    color: #ffffff;
    top: 35%;
    left: 78%;
    animation-duration: 90s;
    animation-delay: -92s;
    transform-origin: -15vw 2vh;
    box-shadow: -38vmin 0 5.056641336623471vmin currentColor;
}
