/* FONTS
===========================
Important:
- Define default font families and font styles here.
- Design titles only using .d1, .d2... Not h1, h2...
- Use EM font sizes and adjust responsive by setting body font size.
- Default body font size is 16px.
*/

$font-title: 'Poppins', sans-serif;
$font-content: 'Nunito Sans', sans-serif;
$font-fancy: '', sans-serif;
$font-card: 'Nunito Sans', sans-serif;

$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;

body {
    font-family: $font-content;
    font-weight: 300;
    color: $grey-120;
    font-size: rem(14);
    line-height: rem(20);
}

h1 {
    font-weight: $fw-300;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-title;

    p {
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
        font-weight: inherit;
        color: inherit;
    }
}

.h3-to-h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #ee3b54;
    margin-top: 0;
}

.h4-to-h3, #page-portfolio-new .h4-to-h3.portfolio-subtitle, #page-portfolio-new .h4-to-h3.portfolio-subtitle p {
    font-size: 1.75rem;
    margin-bottom: 1.2rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #ee3b54;
    margin-top: 0;
    text-transform: initial;
}


.text-uppercase {
    text-transform: uppercase
}

.text-capitalize {
    text-transform: capitalize
}

.text-thin {
    font-weight: 100
}

.text-normal {
    font-weight: 400
}

.text-bold {
    font-weight: 700
}

.text-extrabold {
    font-weight: 900
}

.text-fancy {
    font-family: $font-fancy
}

.violet-hr {
    max-width: 50px;
    margin-left: 0;
    border-width: 3px;
    border-top-color: $violet-90;
    text-align: left;
    margin-top: 12px;
}

blockquote {
    background: #f7f7f7;
    border-left: 4px solid #ccc;
    padding: 0.5em 20px;

    &::before {
        content: open-quote;
    }

    &::after {
        content: close-quote;
    }
}

.thank-you-message {
    display: flex;
    align-items: center;
    gap: rem(12);
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
    color: #0A520D;
    background: #DDF8DE;
    padding: 16px;
    margin-bottom: 16px;

    & img {
        height: 24px;
        width: 25px;
    }
}

p {
    margin-bottom: 0;

    &.card-txt {
        padding-bottom: 0;
    }
}

ul, ol {
    margin-bottom: 0;
    padding-bottom: 0;
}

p, li, a {
    line-height: normal;
    font-size: 14px;
}

.template-flex-page, .template-detail-article-page {
    .solutions-info-item ul li {
        margin-bottom: 0;
        padding-bottom: 0.5rem;
        line-height: normal;
    }
}


ul {
    list-style-image: url('../images/list-marker.svg');
}

ul, ol {
    padding-left: 11px;
    margin-bottom: 1rem;

    li {
        padding-left: 5px;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

blockquote {
    margin-bottom: 2rem;
}

h1.blog-item-title, .flex-page-block-container h1 {
    font-size: 36px;
    font-weight: bold;
    font-family: Poppins, sans-serif;
    padding-bottom: 1.5rem;
    margin-bottom: 0;
}

a {
    color: $violet-90;

    &:hover, &:focus, &:active, &:active:focus, &:active:hover {
        color: $violet-110;
    }
}

.fw-300 {
    font-weight: $fw-300;
}

.fs-36 {
    font-size: rem(36);
    line-height: 125%;
    @media (max-width: 575px) {
        font-size: rem(32);
        line-height: 125%;
    }
}

.fs-32 {
    font-size: rem(32);
    line-height: 125%;
    @media (max-width: 575px) {
        font-size: rem(24);
        line-height: 125%;
    }
}

.fs-24 {
    font-size: rem(24);
    line-height: 125%;
}

.fs-16 {
    font-size: rem(16);
    line-height: rem(24);
}

.fs-14 {
    font-size: rem(14);
    line-height: rem(20);
}

.rich-text {
    margin-bottom: 1.5rem;

    * {
        word-break: break-word;
    }

    h1 {
        font-size: rem(36);
        line-height: 125%;
        padding: 1.5rem 0;
        @media (max-width: 880px) {
            font-size: rem(30);
            line-height: 125%;
        }
        @media (max-width: 575px) {
            font-size: rem(24);
            line-height: 125%;
        }
    }

    h2 {
        font-size: rem(32);
        line-height: 125%;
        padding: 1.5rem 0;
        @media (max-width: 575px) {
            font-size: rem(24);
            line-height: 125%;
        }
    }

    h3 {
        font-size: rem(24);
        line-height: 125%;
        padding: 1.5rem 0;
    }

    h4 {
        font-size: rem(16);
        line-height: rem(24);
        padding: 1rem 0;
    }

    h5 {
        font-size: rem(14);
        line-height: rem(20);
        padding: rem(14) 0;
    }

    h6 {
        font-size: rem(12);
        line-height: rem(18);
        padding: rem(12) 0;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: $fw-300;
    }

    p, a, li, blockquote {
        font-size: rem(16);
        line-height: rem(24);
        font-style: normal;
        font-weight: $fw-300;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }

    li {
        ul, ol {
            margin-top: rem(16);
        }
    }

    p {
        margin-bottom: 0;
        padding-bottom: 1rem;
    }

    b, strong {
        font-weight: $fw-500;
    }

    *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    img {
        max-width: 100%;
        object-fit: contain;
    }
}

.heading-h2 {
    color: $grey-120;
    font-size: rem(28);
    margin-bottom: 32px;
    font-weight: 300;

    @media (max-width: 767px) {
        font-size: rem(22);
        margin-bottom: 22px;
    }
}

.heading-h3 {
    color: $grey-120;
    font-size: rem(24);
    margin-bottom: 24px;
    font-weight: 300;

    @media (max-width: 767px) {
        font-size: rem(20);
        margin-bottom: 20px;
    }
}
