.accordion-faq {
    &__title {
        font-weight: $fw-300;
        font-family: $font-title;
        padding-top: 32px;
        padding-bottom: 16px;
        border-top: 1px solid $grey-30;
        margin-bottom: 0;
    }

    &-container {
        padding-bottom: 48px;
    }

    &__card {
        &-body {
            border-bottom: 1px solid $grey-30;
            color: $grey-110;
            padding-bottom: 16px;
            margin-bottom: 0;
        }
    }

    &__btn {
        font-family: $font-title;
        font-weight: $fw-500;
        border: initial;
        background-color: initial;
        padding: 0;

        &.collapsed {
            padding: 16px 0;
            border-bottom: 1px solid $grey-30;
        }

        &:not(.collapsed) {
            margin-bottom: 8px;
            padding-top: 16px;

            .accordion-faq__icon {
                transform: rotate(0deg);
            }
        }
    }

    &__icon {
        width: 24px;
        height: 24px;
        transform: rotate(180deg);
    }
}
