/** card **/

.card {
    padding: 30px;
}

.card-white,
.card-white-parent {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
}


.card-white {
    padding: 18px;
    align-items: center;
    @media (max-width: 575px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 24px;
    }

    .card-icon {
        margin-right: 64px;
        min-width: 126px;
        width: 126px;
        height: 61px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .card-icon-alt {
        margin-right: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 52px;
        min-width: 52px;
        border-radius: 50%;
        color: $white;
        font-family: $font-title;
        font-weight: 700;
        font-size: 16px;
    }

    .card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: $font-card;

        .card-title {
            font-weight: 500;
            font-size: 16px;
            color: $grey-120;
            margin-bottom: 4px;
        }

        .card-txt {
            font-size: 10px;
            color: $black;
            margin-bottom: 0;

            *:last-child {
                padding-bottom: 0;
            }

            &.card-link {
                margin-left: 0;
            }

            span.date {
                color: rgba(0, 0, 0, 0.6);
            }
        }

        .card-link:hover {
            color: $color-primary;
        }
    }

    @include breakpoint(sm) {
        padding: 32px 24px;
    }
}

.card-grey {
    cursor: pointer;
    background-color: #222222;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 32px;
    align-items: center;
    .card-icon {
        margin-right: 0;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 52px;
        min-width: 52px;
        border-radius: 50%;
        color: $white;
        font-family: $font-title;
        font-weight: 700;
        font-size: 16px;
    }
    .card-content {
        display: flex;
        flex-direction: column;
        font-family: $font-card;
        .card-title {
            font-weight: 700;
            font-size: 18px;
            color: $white;
            margin-bottom: 22px !important;
            padding-bottom: initial !important;
            text-align: center;
        }
        .card-txt {
            font-size: 14px;
            color: $white;
            margin-bottom: 0;
            p {
                margin-bottom: 0;
            }
        }
    }
    @include breakpoint(sm) {
        align-items: flex-start;
        flex-direction: row;
        .card-icon {
            margin-right: 24px;
            margin-bottom: 0;
        }
        .card-content {
            .card-title {
                text-align: left;
            }
        }
    }
}

.card-grey .card-content .card-txt > *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

.card-primary {
    background-color: $color-primary;
}

.card-comment {
    height: 100%;
    .card-content {
        height: 100%;
        .card-txt {
            flex-grow: 1;
            flex-shrink: 1;
            margin-bottom: 20px;
        }
        .card-avatar {
            display: flex;
            align-items: center;
            .avatar-img {
                height: 24px;
                width: auto;
                border-radius: 50%;
                background-position: center;
                margin-right: 10px;
            }
            .avatar-name {
                font-family: $font-card;
                font-size: 12px;
                color: $black;
                margin-bottom: 0;
                padding-bottom: initial;
                .avatar-position {
                    color: rgba(0, 0, 0, 0.6);
                }
            }
        }
    }
}

@media (max-width: 992px) {
    #career-overlap {
        .card-txt {
            p {
                color: white;
            }
        }
    }
}

.margin-bottom-sm-alt,
.margin-bottom-sm {
    margin-bottom: 15px;
}
