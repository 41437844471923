.landing-page-content {
    margin-top: -50px;
    position: relative;

    &::after {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        content: '';
        display: block;
        background-image: url(../images/BG-landing-decor.svg);
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: right 20%;
    }

}

.flex-page-block-container .violet-hr {
    display: none;
}
