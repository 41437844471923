.blogs-page {
    .blog-list-content {
        padding-top: 30px;
        padding-bottom: 50px;
    }

    .filters-btn {
        display: none;
        border: 1px solid $grey-30;
        background-color: $white;
        padding: 12px 16px;
        text-transform: uppercase;
        margin-bottom: 8px;

        @media (max-width: 991px) {
            display: flex;
            align-items: center;
            gap: 8px;

        }

        & .open {
            transform: rotate(180deg);
        }
    }

    .article-body-text {
        font-size: 16px;
        line-height: 1.5rem;
    }
}

.blog-article-wrapper {
    margin-right: auto !important;
    margin-left: auto !important;
}

.article-category {
    background-color: $white;
    color: $grey-110;
    border: 1px solid $grey-30;
    font-weight: 300;
    padding: 3px 12px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    transition: all 0.3s ease;
    max-width: fit-content;
    margin: 0 0 10px 0;
    text-align: left;

    &:hover {
        background-color: $white;
        color: $violet-90 !important;
        border: 1px solid $violet-50;
    }

    @media (max-width: 991px) {
        margin: 0 10px 10px 0;
    }

    &.active {
        background-color: $white;
        color: $violet-90 !important;
        border: 1px solid $violet-50;
    }
}

.filters-container {
    display: flex;
    flex-direction: column;
    @media (max-width: 991px) {
        flex-direction: row;
    }
}

.article-card {
    text-decoration: none !important;
    letter-spacing: 0.5px;
    margin-bottom: 48px;

    &:hover .article-head {
        color: $violet-90;
    }

    .article-head {
        color: $grey-120;
        margin-bottom: 24px;
        font-size: 2rem;
        font-weight: 300;

        @media (max-width: 1176px) {
            font-size: 1.5rem;
            line-height: 46px;
        }

        @media (max-width: 935px) {
            font-size: 1.4rem;
            line-height: 30px;
        }

        @media (max-width: 801px) {
            font-size: 1.4rem;

        }
    }

    .article-body-text {
        color: $grey-110;
        font-size: 16px;
        margin-bottom: 24px;
    }

    .article-category-title {
        color: $grey-120;
        cursor: pointer;
        font-weight: 400;
    }

    .article-date {
        color: $grey-120;
        font-weight: 400;
    }
}

.article-date-category-divider {
    background-color: $grey-120;
    font-weight: 400;
    width: 4px;
    height: 4px;
    display: flex;
    align-self: center;
    border-radius: 50%;
    margin: 0 10px;
}

// pagination
.pagination-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 2rem;
}

ul.pagination {
    padding-left: initial;
    padding-bottom: initial;
}

.pagination > li {
    height: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: initial;
    padding-bottom: initial;
    font-weight: 300;
    font-size: 16px;
    line-height: 2rem;
    margin-bottom: 0;
}

.pagination > li > a,
.pagination > li > span {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $grey-dark;
    border-color: #fff;
    font-weight: 300;
    font-size: 16px;
    line-height: 2rem;
    padding: 0 10px;
}

.disabled,
.disabled:hover {
    background-color: #e1e1e1;
    cursor: default;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background-color: $grey-120;
    color: $white;
}
