/* PAGES */

// Lenis Global starts

html.freeze {
    overflow: hidden;

    body {
        overflow: hidden;
        height: 100vh;
    }
}

html {
    scroll-behavior: smooth;

    font-size: 100%;

    @media (max-width: 575px) {
        font-size: 85%;
    }
}

.lenis.lenis-smooth {
    scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}

// Lenis Global ends

// Scroll Reveal
// html.sr .slide-right, html.sr .slide-left, html.sr .slide-up, html.sr .slide-down {
//     visibility: hidden;

//     @media(max-width: 767px) {
//         visibility: visible;
//     }
// }


::placeholder {
    color: inherit;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    color: inherit; /* Internet Explorer 10-11 */
}

::-ms-input-placeholder {
    color: inherit; /* Microsoft Edge */
}

/* PAGES SHARED*/
body {
    max-width: 100%;
    font-family: $font-content;
}

.intro-txt {
    color: $white;
    text-align: center;
    font-family: $font-title;
    font-size: 20px;
    margin-bottom: 0;
}

/* SPLIT PAGES */

@media (min-width: $screen-xxs) {
    .split-page-right-section {
        padding: 0 15px !important;
    }
    .apply-now-title {
        padding: 50px 0 20px 0;
    }
}

@media (min-width: $screen-md) {
    .apply-now-title {
        padding: 0;
    }
}

/* HERO SECTION*/
.home-hero {
    margin-bottom: 0;

    @media (max-width: 575px) {
        margin-bottom: 0;
    }

    .home-hero-txt {
        @media(max-width: 991px) {
            margin-top: 40px;
        }
    }

    .hero-btn-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 20px;

        @media(max-width: 767px) {
            width: 100%;

            .bttn {
                display: block;
                width: 100%;
            }
        }
    }

    .home-hero-images {
        margin-left: auto;
        display: grid;
        grid-template-columns: 138px 138px 138px;
        gap: 14px;
        justify-content: end;
        margin-top: 55px;
        padding-bottom: 61px;

        @media(max-width: 991px) {
            justify-content: center;
            margin-top: 90px;
        }

        @media(max-width: 767px) {
            grid-template-columns: repeat(3, 1fr);
            width: 90%;
            margin-right: auto;
            margin-left: auto;
        }

    }

    .home-hero-img {
        width: fit-content;
        align-self: end;
        border-radius: 5px;
        overflow: hidden;

        & img {
            height: 234px;
            width: 138px;
            object-fit: cover;
        }

        @media(max-width: 767px) {
            width: 100%;
            img {
                width: 100%;
                height: auto;
            }
        }

        &:nth-child(1) {
            position: relative;
            top: 60px;
        }

        &:nth-child(2) {
            position: relative;
            top: 5px;
        }

        &:nth-child(3) {
            position: relative;
            top: -50px;
        }

        &:nth-child(4) {
            position: relative;
            top: 60px;
        }

        &:nth-child(5) {
            position: relative;
            top: 5px;
        }

        &:nth-child(6) {
            position: relative;
            top: -50px;
        }
    }

}

/* PRE FOOTER HERO */
.pre-footer-hero {
    background-color: #fff;
    //overflow-y: hidden;
    //@media screen and (min-width: 992px) {
    //    height: 680px;
    //}
    .pre-footer-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        //height: 30%;
        @media screen and (max-width: 820px) {
            align-items: center;
        }
        //@media screen and (min-width: 1200px) {
        //    margin-top: 22%;
        //}
        .pre-footer-caption {
            font-family: $font-content;
            font-size: 30px;
            line-height: 45px !important;
            color: $grey-120;
            margin-bottom: 25px;
            @media screen and (max-width: 575px) {
                font-size: rem(24);
                line-height: 32px !important;
            }
            @media screen and (max-width: 820px) {
                text-align: center;
            }
        }

        .pre-footer-cta {
            margin-bottom: 50px;

            .pre-footer-btn {
                margin-right: 15px;
            }

            a.pre-footer-link {
                color: $violet-90;
                position: relative;
                display: inline-block;

                &:hover {
                    color: $violet-110;
                    text-decoration: underline;
                }
            }
        }
    }

    .pre-footer-images {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        @media screen and (max-width: 1199px) {
            margin-bottom: 100px;
        }
        @media screen and (min-width: 1200px) {
            justify-content: flex-end;
            padding-bottom: 15%;
        }
        @media screen and (max-height: 768px) {
            margin-top: 120px;
        }
        @media screen and (min-width: 1200px) and (max-width: 1439px) {
            transform: scale(0.95) translateY(-20px);
        }

        .pre-footer-img {
            height: 216px;
            width: 100px;
            position: relative;
            margin-bottom: 18px;
            margin-right: 15px;

            @include breakpoint(xl) {
                height: 234px;
                width: 137px;
                margin-bottom: 12px;
                margin-right: 10px;
            }

            img {
                position: absolute;
                width: 100%;
                height: auto;
                background-size: cover;
                background-position: center;
                border-radius: 5px;
            }

            &:nth-child(1) {
                order: 1;
                @media screen and (min-width: 1200px) {
                    order: 3;
                }

                img {
                    transform: translateY(100px);
                    @include breakpoint(xl) {
                        transform: translateY(53px);
                    }
                }
            }

            &:nth-child(2) {
                order: 2;

                img {
                    transform: translateY(72px); // 36px * 2
                    @include breakpoint(xl) {
                        transform: translateY(106px); // 53px * 2
                    }
                }
            }

            &:nth-child(3) {
                order: 3;
                @media screen and (min-width: 1200px) {
                    order: 1;
                }

                img {
                    transform: translateY(28px); // 36px * 3
                    @include breakpoint(xl) {
                        transform: translateY(159px); // 53px * 3
                    }
                }

                @media screen and (max-width: 767px) {
                    display: none;
                }
            }

            &:nth-child(4) {
                order: 4;
                @media screen and (min-width: 1200px) {
                    order: 6;
                }

                img {
                    transform: translateY(100px);
                    @include breakpoint(xl) {
                        transform: translateY(61px);
                    }
                }

                @media screen and (max-width: 575px) {
                    display: none;
                }
                @media screen and (min-width: 768px) and (max-width: 1199px) {
                    order: 5;
                }
            }

            &:nth-child(5) {
                order: 5;

                img {
                    transform: translateY(72px); // 36px * 2
                    @include breakpoint(xl) {
                        transform: translateY(106px); // 53px * 2
                    }
                }

                @media screen and (max-width: 767px) {
                    display: none;
                }
                @media screen and (min-width: 768px) and (max-width: 1199px) {
                    order: 4;
                }
            }

            &:nth-child(6) {
                order: 6;
                @media screen and (min-width: 1200px) {
                    order: 4;
                }

                img {
                    transform: translateY(28px); // 36px * 3
                    @include breakpoint(xl) {
                        transform: translateY(159px); // 53px * 3
                    }
                }

                @media screen and (max-width: 1200px) {
                    display: none;
                }
            }

        }
    }
}

@media (max-width: 1199px) {
    .pre-footer-hero {
        .pre-footer-images {
            .pre-footer-img:nth-child(1) img {
                transform: translateY(36px) !important;
            }

            .pre-footer-img:nth-child(3) img {
                transform: translateY(28px) !important;
            }

            .pre-footer-img:nth-child(4) img {
                transform: translateY(28px) !important;
            }
        }

    }
}

/* Careers Page */

.flex-end {
    justify-content: flex-end !important;
}

/* Landing Page */

.landing-application {
    @include breakpoint(lg) {
        padding: 60px 0 15px 60px;
        flex-direction: column;
    }

    .application {
        flex-shrink: 1;
        flex-grow: 1;
    }

    .signature {
        color: $white;
        max-width: 500px;
    }

    .landing-why-title {
        font-size: 30px;
    }
}

.landing-description {
    .card-white {
        padding: 20px;
    }

    .case-study-preview {
        background-position: center;
        background-size: cover;
        border-radius: 8px;
        width: 100%;
        height: 255px;
    }

    .case-study-txt {
        font-family: Poppins;
        font-size: 14px;
        color: $black;

        p {
            margin-bottom: 25px;
        }
    }
}

/* NixaLife Page */

.nixa-life-memories {
    .nixa-life-title {
        font-family: $font-title;
        font-size: 50px;
        text-align: center;
        font-weight: bold;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        color: $grey-dark;
        margin-top: -14px;
    }

    .nixa-life-subtitle {
        font-family: $font-title;
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        color: $grey-dark;
    }

    .memories-items {
        .col-lg-6 {
            padding-right: 25px;
            padding-left: 25px;
        }

        .memory {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 10px;

            .memory-img {
                height: 250px;
                margin-bottom: 25px;
                width: 100%;
                background-position: center;
                background-size: cover;
                border-radius: 10px;
            }
        }

        .caption {
            font-family: $font-content;
            font-size: 14px;
            color: $grey-dark;
            text-align: left;
            padding: 0;
        }
    }

    @include breakpoint(md) {
        .nixa-life-title {
            font-size: 96px;
            margin-top: -24px;
        }
        .memories-items {
            .mt-5:nth-child(even) {
                margin-top: 6rem !important;
            }
        }
    }
}

/* Contact Page */

.contact-application {
    display: flex;
    flex-direction: column;

    .application {
        flex-shrink: 1;
        flex-grow: 1;
    }

    .signature {
        color: $white;
        padding-top: 155px;
    }
}

.contact-elments {
    flex-shrink: 1;
    flex-grow: 1;
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .contact-elments {
        padding: 0 15px;
    }
}

/* Blog Page */

.hidden {
    display: none !important;
}

.nixa-life-memories-bottom-section {
    max-width: 1000px;
    margin: 0 auto;
    padding: 15px 15px 50px 15px;

    .left-side2 {
        display: flex;
        justify-content: right;
    }

    @media (max-width: 767px) {
        .left-side2 {
            justify-content: center;
            margin-bottom: 50px;
        }
    }
}

@media (min-width: 992px) {
    .pb-lg-50 {
        padding-bottom: 50px;
    }
}

.margin-sides-auto {
    margin-left: auto;
    margin-right: auto;
}

.fade-imgs {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: linear-gradient(
            140deg,
            rgba(38, 37, 37, 0) 30%,
            rgba(38, 37, 37, 0.6) 50%
    );
}

@media (min-width: 992px) {
    #career-hero,
    #career-details-hero {
        box-shadow: none;
    }
}

/** social_responsibility.html **/
@media (min-width: 992px) {
    section[data-pageid="social-responsibility"] {
        background: linear-gradient(90deg, transparent 50%, #262525 50%);

        .body-right-column {
            margin-top: 20vw;
            padding-left: 60px;
        }
    }
    div[data-pageid="social-responsibility-hero"] {
        .section-filter {
            background-color: transparent;
        }
    }
}

@media (max-width: 992px) {
    section[data-pageid="social-responsibility"] {
        padding-bottom: 0;
    }
}

/**  CareersDetailsPage.html  **/
section[data-pageid="careers-info"] {
    .content-wrapper {
        .content-col {
            ul {
                list-style: initial;
                margin-left: 20px;
            }
        }
    }
}

@media (min-width: 992px) {
    section[data-pageid="careers-info"] {
        background: linear-gradient(90deg, transparent 50%, #262525 50%);

        .signature-wrapper {
            display: flex;
            gap: 25px;

            div:nth-child(1) {
                display: flex;
                flex: 0 0 50%;
                justify-content: right;
                padding-right: 25px;
            }

            div:nth-child(2) {
                color: white;

                .bttn-black {
                    color: white;
                    border-color: white;
                }
            }
        }

        .content-wrapper {
            display: flex;
            justify-content: space-evenly;
            gap: 120px;

            .content-col {
                flex: 0 0 50%;

                ul {
                    list-style: initial;
                    margin-left: 20px;
                }
            }

            .content-col:nth-child(1) {
                padding-left: 50px;
            }
        }
    }
    div[data-pageid="careers-info-hero"] {
        .section-filter {
            background-color: transparent;
        }
    }
}

.list-unstyled {
    line-height: 2;
}

.template-career-listing-page, .template-career-detail-page, .template-contact-us-page {
    @media only screen and (max-width: 370px) {
        .card-white {
            align-items: center;
            flex-direction: column;

            .card-icon {
                margin-right: initial;
                margin-bottom: 20px;
            }

            .card-content {
                text-align: center;
            }
        }
    }
}

.template-solutions-detail-page {
    @media screen and (max-width: 1199px) {
        .home-hero a.bttn {
            display: block;
            margin-bottom: 20px;
            margin-right: initial;
        }
    }

}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes blinking {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    60% {
        opacity: 0.9;
    }
    100% {
        opacity: 0.9;
    }
}

:root {
    --after-top: 150px;
}

