.statistics-highlight-section {
    margin-bottom: 70px;
}

.statistics-highlight-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 1rem;
    height: 400px;

    & .feature-panel {
        grid-column: 1;
        grid-row: 1 / span 2;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    & .feature-background {
        background-image: url('../images/bg-lines4.png');
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    & .feature-content {
        padding: 2rem;
        position: relative;
        z-index: 1;
        height: 100%;
        display: flex;
        flex-direction: column;

        & .feature-eyebrow {
            font-size: 14px;
            text-transform: uppercase;
        }

        & .heading-h3 {
            font-size: 2rem;
        }
    }

    & .feature-icons {
        display: flex;
        gap: 24px;
        margin-top: auto;

        & .feature-icon {
            height: 62px;
            width: 62px;

            & img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}


.primary-stat-panel {
    grid-column: 2;
    grid-row: 1;
    position: relative;
    overflow: hidden;
    color: white;

    & .primary-stat-background {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center;
        z-index: 0;

    }

    & .primary-stat-content {
        position: relative;
        z-index: 1;
        padding: 2rem;
        height: 100%;
        display: flex;
        flex-direction: column;

        & .heading-h3 {
            font-size: 2rem;
            color: $white;
            font-weight: 500;
            margin-bottom: 10px;
        }

        & .primary-stat-caption {
            font-size: 14px;
        }
    }
}


.secondary-stats-container {
    grid-column: 2;
    grid-row: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.secondary-stat-panel {
    position: relative;
    overflow: hidden;
    border: 1px solid #E6E6E6;
}

.light-panel {
    color: $black;
}

.dark-panel {
    color: white;
}

.secondary-stat-background {
    background-image: url('../images/bg-lines4.png');
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: 0;
}

.light-panel .secondary-stat-background::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
}

.dark-panel .secondary-stat-background::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
}

.secondary-stat-content {
    position: relative;
    z-index: 1;
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.secondary-stat-value {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.secondary-stat-caption {
    font-size: 0.9rem;
    opacity: 0.9;
}

/* Responsive adjustments */
@media (max-width: 767px) {
    .statistics-highlight-grid {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        height: auto;
    }

    .feature-panel {
        grid-column: 1 !important;
        grid-row: 1 !important;

        & .feature-icons .feature-icon {
            height: 40px;
            width: 40px;
        }
    }

    .primary-stat-panel {
        grid-column: 1;
        grid-row: 2;
    }

    .secondary-stats-container {
        grid-column: 1;
        grid-row: 3;
    }
}

