.flex-page-block-container {
    padding-top: 50px;
    @media (max-width: 991px) {
       padding-top: 30px;
    }
    &:last-child {
        padding-bottom: 50px;
        @media (max-width: 991px) {
          padding-bottom: 30px;
        }
    }
    .solutions-info-item > *:last-child {
        margin-bottom: 0;
    }

}
