.portfolio-techno-filter-section {
    text-align: center;
    padding-bottom: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    @media(min-width: 576px) {
        padding: 48px 0;
    }

    @media(min-width: 1200px) {
        padding: 64px 0;
    }

    .portfolio-techno-filter {
        background-color: $white;
        border: 1px solid $grey-30;
        color: $grey-100;
        display: inline-flex;
        margin-bottom: 0;
        padding: 4px 12px;
        transition: all 0.2s linear;
        align-items: center;
        gap: 4px;
        justify-content: flex-start;
        cursor: pointer;

        &:hover, &:focus-visible {
            border-color: $violet-50;
            color: $violet-90;
        }

        &.checked {
            border-color: $violet-50;
            color: $violet-90;
            padding-right: 10px;

            .portfolio-techno-filter-icon {
                width: 16px;
                height: 16px;
            }
        }

        &-icon {
            width: 0;
            height: 0;
            transition: all 0.2s linear;
        }

        &[data-active="true"] {
            .portfolio-techno-filter-item {
                background-color: #ee3b54 !important;
                border: none;
                color: #fff;
                cursor: pointer;
            }
        }

        input[type=checkbox] {
            visibility: hidden;
            width: 0;
            height: 0;
        }
    }
}

.portfolio-item-wrapper {
    transition: all 0.15s linear;

    &:hover {
        background-color: $violet-10;
    }

    .portfolio-item-content {
        display: flex;
        margin: 0 auto;
        padding: 24px 15px;
        @media (min-width: 1200px) {
            gap: 32px;
        }

        .portfolio-image-right-section {
            flex: 0 0 317px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (max-width: 767px) {
                flex: 0 0 178px;
            }

            p {
                display: block;
                height: 93px;
                max-width: 288px;
                width: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .portfolio-info-left-section {
            .portfolio-title {
                font-weight: $fw-300;
                color: $grey-120;
                font-size: rem(30);

                @media (max-width: 880px) {
                    font-size: rem(30);
                    line-height: 125%;
                }
                @media (max-width: 575px) {
                    font-size: rem(24);
                    line-height: 125%;
                }

                &:hover {
                    color: $violet-90;
                }

            }

            .portfolio-subtitle {
                padding-bottom: initial;

                p {
                    font-size: rem(16);
                    font-weight: $fw-300;
                    line-height: rem(24);
                    color: inherit;
                    margin-bottom: 24px;
                    display: block;
                    color: $grey-120;
                    &:hover {
                        color: $violet-90;
                    }
                }
            }

            .portfolio-info {
                list-style: none;
                padding: 0;

                .portfolio-info-item {
                    margin-bottom: 8px;

                    &__title {
                        font-family: $font-title;
                        font-size: rem(14);
                        font-weight: $fw-500;
                        color: $grey-120;
                    }

                    &__content {
                        font-size: rem(14);
                        font-weight: $fw-300;
                        word-break: break-all;
                        color: $grey-120;
                        transition: all 0.2s linear;

                        &:hover {
                            color: $violet-90;
                        }
                    }
                }
            }
        }

        .portfolio-techno-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            margin-bottom: 24px;

            .portfolio-techno {
                background-color: $white;
                border: 1px solid $grey-30;
                color: $grey-100;
                display: inline-flex;
                margin-bottom: 0;
                padding: 4px 8px;
                transition: all 0.2s linear;
                align-items: center;
                gap: 4px;
                justify-content: flex-start;
                cursor: pointer;

                &:hover, &:focus-visible {
                    border-color: $violet-50;
                    color: $violet-90;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .portfolio-item-content {
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: flex-start;

            .portfolio-image-right-section {
                p {
                    margin: 0 auto;
                }
            }
        }
    }
}

.case-study-no-result {
    .no-result-text a {
        cursor: pointer;
        border-radius: 100px;
        padding: 6px 12px;
        margin-right: 5px;
        line-height: 30px;
        white-space: nowrap;
        color: $grey-dark;
        background-color: #e1e1e1;

        &:hover {
            background-color: #ee3b54 !important;
            border: none;
            color: #fff;
        }
    }
}

.case-studies-pagination-container {
    display: flex;

    .row {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3rem;
    }
}

#portfolio-techno-filters-collapse {
    &.collapse:not(.show) {
        display: block;
        @media (max-width: 575px) {
            display: none;
        }
    }
}
