.award {
    position: relative;

    &__title {
        font-size: 2rem;
        font-weight: 300;
        color: $grey-120;
        padding-bottom: 24px;

        @media (max-width: 991px) {
            font-size: rem(32);
        }

        @media (max-width: 575px) {
            font-size: rem(24);
        }
    }

    & .right-line {
        position: absolute;
        background-color: $grey-30;
        display: block;
        width: 25px;
        height: 1px;
        right: -9px;
        top: 10px;
        z-index: -1;
        @media (max-width: 767px) {
            display: none;
        }
    }

    & .left-line {
        position: absolute;
        background-color: $grey-30;
        display: block;
        width: 25px;
        height: 1px;
        left: -9px;
        top: 10px;
        @media (max-width: 767px) {
            display: none;
        }
    }
}

.award-card {
    border: 1px solid $grey-30;

    &__content {
        padding: 48px 24px 24px;
        transition: all 0.3s linear;

        &:hover {
            background-color: #fbfbfb;
        }

        &:hover .award-card__date {
            color: #3f1bff;
        }
    }

    &__title {
        font-size: 14px;
        font-weight: 500;
        color: $grey-120;
        filter: brightness(0.5);
    }

    &__description {
        font-size: 14px;
    }

    &__date {
        font-size: 24px;
        font-weight: 700;
        padding-bottom: 0;
        color: $grey-80;
        text-align: end;
        transition: all 0.3s linear;
    }
}

.award-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.swiper-left, .swiper-right {
    filter: brightness(0);
    width: 32px;
    height: 32px;
}

.swiper-left.swiper-button-disabled {
    filter: brightness(1);
}

.swiper-right.swiper-button-disabled {
    filter: brightness(1);
}
