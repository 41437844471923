.expertise {
    overflow: hidden;
    display: flex;
    gap: 32px;
    margin-bottom: 70px;

    @media (max-width: 767px) {
        flex-wrap: wrap;
    }

    & .expertise__content {
        position: relative;
        max-width: 380px;
        flex: 1;
        border: 1px solid $grey-40;
        padding: 48px 32px;
        @media (max-width: 767px) {
            width: 100%;
            max-width: 100%;
            flex: auto;
        }

        & h2 {
            color: $grey-120;
            font-size: rem(28);
            margin-bottom: 32px;
            font-weight: 300;
            @media (max-width: 767px) {
                font-size: rem(22);
                margin-bottom: 22px;
            }
        }

        & span {
            text-transform: uppercase;
            font-size: rem(14);
            color: $grey-100;
            font-weight: 600;
            display: block;
            margin-bottom: 10px;

        }

        & p {
            font-size: rem(16);
            margin-bottom: 36px;
        }

        & .swiper-navigation {
            position: absolute;
            bottom: 0;
            z-index: 1;
        }
    }

    & .expertise-swiper {
        position: relative;
        flex: 2;
        @media (max-width: 767px) {
            flex: auto;
        }

        & .expertise-card {
            @media (max-width: 767px) {
                padding-bottom: 20px;
            }

            &__content {
                width: 100%;
            }

            & img {
                margin-bottom: 36px;
                object-fit: cover;
                width: 100% !important;
                height: 262px;
                @media (max-width: 767px) {
                    margin-bottom: 18px;
                }
            }

            & h3 {
                font-size: rem(22);
                font-weight: 300;
                color: $grey-120;
                margin-bottom: 16px;
            }
        }

        & .swiper-navigation {
            position: absolute;
            bottom: 0;
            z-index: 1;
        }
    }


}
