/* FORMS =========================== */
.form-control {
    font-size: 16px; //ios form autozoom fix
    @include breakpoint(sm) {
        font-size: 14px;
    }
}

/* CHECKBOX */

.nx-checkbox {
    position: relative;
    text-align: left;

    &:hover {
        cursor: pointer;
    }

    label {
        font-family: $font-content;
        font-size: 1rem;
        font-weight: 300;
        display: inline-block;
        width: calc(100% - 22px);
        padding-left: 12px;

        &:hover {
            cursor: pointer;
        }
    }

    input[type="checkbox"] {
        visibility: hidden;
        display: inline;
    }

    label {
        &:before,
        &:after {
            content: "";
            background-color: $white;
            border-radius: 0;
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            top: 0;
            cursor: pointer;
            margin-top: 1px;
            transition: all 500ms ease;
        }

        &:before {
            border: 1px solid $grey-60;
            border-radius: 4px;
        }

        &:after {
            content: " ";
            background: transparent;
            border: solid $white;
            border-width: 0 2px 2px 0;
            display: inline-block;
            width: 4px;
            height: 9px;
            position: absolute;
            top: 3px;
            left: 6px;
            transform: scale(0) rotate(45deg);
        }

        &:active:before {
            border-color: $color-secondary;
        }

        &:hover:before {
            border-color: $color-secondary;
        }
    }

    input[type="checkbox"]:checked + label {
        &:before {
            background-color: $color-secondary;
            border-color: $color-secondary;
        }

        &:after {
            transform: scale(1) rotate(45deg);
        }

        &:active:before {
            background-color: darken($color-secondary, 20%);
            border-color: darken($color-secondary, 20%);
        }

        &:hover:before {
            background-color: darken($color-secondary, 10%);
            border-color: darken($color-secondary, 10%);
        }
    }
}

/* CUSTOM RADIO */

.radio-custom {
    position: relative;

    input[type="radio"] {
        visibility: hidden;
        display: inline;
    }

    label {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            left: 0;
            top: 2px;
            cursor: pointer;
            background-color: $grey-light;
            transition: all 500ms ease;
            border-radius: 30px;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            left: 4px;
            top: 6px;
            background-color: $color-primary;
            border-radius: 30px;
            transform: scale(0);
        }

        &:active:before {
            background-color: darken($grey-light, 20%);
        }

        &:hover:before {
            background-color: darken($grey-light, 10%);
        }
    }

    input[type="radio"]:checked + label {
        &:after {
            transform: scale(1);
        }
    }

    @include breakpoint(md) {
        label {
            padding-left: 10px;
        }
    }
}

/* CUSTOM SELECT */

.select-custom {
    position: relative;

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    &:before {
        @include fa-icon("\f107");
        position: absolute;
        top: -32px;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 20px;
        font-size: 24px;
        color: $grey-dark;
        pointer-events: none;
    }

    @include breakpoint(lg) {
        &:before {
            height: 25px;
        }
    }
}

/* REMOVE YELLOW AUTOFILL COLOR  */

@-webkit-keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

#career-overlap {
    @media (min-width: 992px) {
        background-color: #262525;
        z-index: 10;
        padding-top: 20px;
    }
}

form.application {
    display: flex;
    flex-direction: column;
    padding: 0;
    max-width: 600px;
    margin: 0 auto;
    width: 100%;

    .application-input-txt,
    .application-textarea {
        background: $white;
        border: 1px solid $grey-60;
        border-radius: 2px;
        margin-bottom: 15px;
        padding: 11px 12px;
        font-size: 14px;
        font-family: $font-content;
        color: $grey-120;

        &:focus {
            color: $grey-dark;
            border-color: $violet-90;
        }

        &.parsley-error {
            border-color: #ee3a51;
        }

        &.parsley-success {
            border-color: #00a651;
        }

        &:focus-visible {
            outline: none;
        }

        &::-webkit-input-placeholder {
            -webkit-text-security: none;
            color: $grey-80;
            direction: inherit !important;
            pointer-events: none !important;
            text-orientation: inherit !important;
            writing-mode: inherit !important;
        }
    }

    .bttn.bttn-primary {
        width: 118px;
        margin: 0 auto;
        margin-bottom: 24px;

    }

    .bttn.bttn-white {
        width: 118px;
        margin: 0 auto;
        margin-bottom: 24px;
    }

    #drag_drop {
        border: 1px dashed #474646;
        border-radius: 5px;
        padding: 12px;
        font-size: 18px;
        color: #474646;
        font-family: $font-content;
        margin-bottom: 25px;
        max-height: 110px;

        svg {
            fill: #474646;
            height: 100px;
        }
    }

    .progress-custom {
        height: 12px !important;
        border-radius: 50px !important;
        width: 236px !important;
        background: $white;
        border: 1px solid #909090;
        margin-bottom: 10px;

        .bg-success {
            background-color: $color-secondary !important;
            height: 100% !important;
            text-align: center;
        }
    }

    .progress_percentage {
        text-align: center;
        color: $grey-dark;
        font-family: $font-content;
        font-size: 1rem;
        font-weight: 100;
        margin-bottom: 10px;
    }
}

// form scroll

@include breakpoint(lg) {
    .formFix {
        position: fixed;
        top: 40px;
        height: 95vh;
        padding: 0;
        width: 100%;
        max-width: 450px;
    }

    .formFixBottom {
        position: absolute;
        width: 100%;
        max-width: 450px;
    }

    .textDown {
        position: absolute;
        bottom: -6px;
    }
}

/* File upload section */
.file-upload-section {
    border: 1px solid $grey-60;
    margin-bottom: 15px;
    background-size: cover;
    background-position: center;

    .file-upload-section-cont {
        position: relative;
        padding: 11px 12px;
        width: 100%;
        color: $grey-80;
        font-size: 20px;
        display: flex;
        gap: 16px;
        flex-direction: row;
        align-items: center;
        font-weight: normal;
        text-align: center;
        cursor: pointer;
        background: $white;
        @media screen and (max-width: 991px) {
            background-color: initial;
        }

        &.trans {
            opacity: 0;
        }

        & .upload-btn {
            border: none;
            position: absolute;
            font-size: rem(14);
            right: 10px;
            background: $grey-100;
            color: $white;
            padding: 6px 16px;
            border-radius: 4px;
        }
    }

    img {
        width: 16px;
    }

    p {
        margin: 0;
    }

    #id_cv {
        border-radius: initial;
        border: initial;
        padding: initial;
        height: 1px;
        opacity: 0;
    }

    &.required-field {
        border: 2px dashed $color-primary;
    }
}

.error-text {
    color: $color-primary;
    position: relative;
    top: -10px;

    ul {
        padding-left: initial;
        margin-bottom: initial;
        list-style: none;
    }

    li {
        margin-bottom: 8px;
    }
}

.border-error {
    border-color: $color-primary !important;
}
