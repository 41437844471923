.job-description-page {
    padding-bottom: 50px;
    @media (max-width: 820px) {
        padding-top: 30px;
    }
    @media (min-width: 992px) {
        .job-description {
            padding: 60px 60px 0 15px;
        }
    }
}
