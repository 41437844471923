.signature {
    display: flex;
    gap: 32px;

    @media (max-width: 991px) {
        flex-direction: column;
        gap: 32px;
    }

    &__first-column {
        display: flex;
        flex: 1;

        * {
            text-align: right;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            padding: 0;
            @media (max-width: 991px) {
                text-align: left;
            }
        }

    }

    &__second-column {
        display: flex;
        align-items: center;
        flex: 1;

        @media (max-width: 767px) {
            p {
                display: grid;
                grid-template-columns: 1fr auto;
                gap: 10px;
            }
        }

        & * {
            padding: 0;
            color: $grey-100;
            font-size: 14px;
            font-weight: 300;
        }

        & b {
            display: inline-block;
            color: $grey-120;
            font-size: 16px;
            min-width: 100px;
            font-weight: 600;
            padding-right: 10px;
            @media (max-width: 991px) {
                min-width: 90px;
            }
        }
    }
}
