.case-study-section {
    font-weight: 300;

    .title {
        font-size: rem(30);
        font-weight: 300;
        color: $grey-120;

        @media(max-width: 575px) {
            font-size: rem(24);
        }
    }

    .case-study-content {
        .case-study-image {
            border-radius: 8px;
            width: 100%;
            height: auto;
        }

        .case-study-txt {
            position: relative;
        }
    }

    .case-study-award {
        display: flex;
        align-items: center;
        gap: 8px;

        & h3 {
            font-size: 16px;
            font-weight: 600;
            color: $grey-120;
            padding: 0;
            margin: 0;
        }

        & img {
            height: 48px;
        }
    }

    .case-study-buttons {
        display: flex;
        flex-wrap: wrap;
        margin: 15px 0;
        gap: 20px;
        @media screen and (max-width: 375px) {
            gap: initial;
            flex-direction: column;
        }

        a {
            &.bttn {
                @media screen and (max-width: 375px) {
                    display: block;
                    margin-bottom: 20px;
                }
            }
        }
    }

    h2 {
        margin-bottom: 20px !important;
        padding-bottom: initial;
    }
}
