.benefits {
    & .heading-h2 {
        margin-bottom: 36px;
    }
}

.benefits-features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 89px 32px;
    margin-bottom: 70px;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
    }


    & .benefit {
        & img {
            background: #EBEDFF;
            margin-bottom: 24px;
        }
    }
}
