/* POSITIONING
=========================== */

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    display: flex;
    flex-direction: column;
}

header {
    flex: 0 0 auto;
}

main {
    flex: 1 1 auto;
    padding-top: 95px;
}

/* SPACING
===========================
Adding a size:
- Create a variable containing value
- Add a label and value in $spacing-sizes, it will create all desired variants
*/

$spacing-xs: 5px;
$spacing-sm-alt: 15px;
$spacing-sm: 21px;
$spacing-md: 68px;
$spacing-lg: 100px;

$spacing-sizes: (
    (sm, $spacing-sm),
    (md, $spacing-md),
    (lg, $spacing-lg),
    (sm-alt, $spacing-sm-alt)
);

@each $label, $size in $spacing-sizes {
    .padding-#{$label} {
        padding-top: #{$size};
        padding-bottom: #{$size};
    }
    .padding-side-#{$label} {
        padding-left: #{$size};
        padding-right: #{$size};
    }
    .padding-top-#{$label} {
        padding-top: #{$size};
    }
    .padding-right-#{$label} {
        padding-right: #{$size};
    }
    .padding-bottom-#{$label} {
        padding-bottom: #{$size};
    }
    .padding-left-#{$label} {
        padding-left: #{$size};
    }
    .margin-#{$label} {
        margin-top: #{$size};
        margin-bottom: #{$size};
    }
    .margin-side-#{$label} {
        margin-left: #{$size};
        margin-right: #{$size};
    }
    .margin-top-#{$label} {
        margin-top: #{$size};
    }
    .margin-right-#{$label} {
        margin-right: #{$size};
    }
    .margin-bottom-#{$label} {
        margin-bottom: #{$size};
    }
    .margin-left-#{$label} {
        margin-left: #{$size};
    }
}

/* SEPARATORS
=========================== */

hr {
    margin-top: $spacing-sm;
    margin-bottom: $spacing-sm;
    border: 0;
    border-top: 2px solid $black;
}

/* ALIGNMENT
=========================== */

.vcenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-sm {
    max-width: 500px;
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
}

.row-narrow {
    margin-left: -7px;
    margin-right: -7px;
    [class*="col"] {
        padding-left: 7px;
        padding-right: 7px;
    }
}

.row-no-padding {
    margin: 0;
    [class*="col-"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.mt-100px {
    margin-top: 100px;
}

.mb-30px {
    margin-bottom: 30px;
}

.mb-lg-68px {
    @media screen and (min-width: 992px) {
        margin-bottom: 68px;
    }
}

.z-10 {
    z-index: 10;
}
.z-105 {
    z-index: 105;
}

.py-12px {
    padding-top: 12px;
    padding-bottom: 12px;
}

.px-32px {
    padding-left: 32px;
    padding-right: 32px;
}

.px-sm-15px {
    @media screen and (min-width: 576px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.h-lg-100 {
    @media screen and (min-width: 991px) {
        height: 100%;
    }
}

.flex-1-1-auto {
    flex: 1 1 auto;
}

.mr-4-from-375 {
    @media screen and (min-width: 375px) {
        margin-right: 1.5rem;
    }
}

.mb-32px {
    margin-bottom: 32px;
}

.py-64px {
    padding-top: 64px;
    padding-bottom: 64px;

    @media (max-width: 767px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    @media (max-width: 550px) {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

.py-48px {
    padding-top: 48px;
    padding-bottom: 48px;

    @media (max-width: 767px) {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    @media (max-width: 550px) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

.pt-64px {
    padding-top: 64px;

    @media (max-width: 767px) {
        padding-top: 48px;
    }

    @media (max-width: 550px) {
        padding-top: 32px;
    }
}

.pb-64px {
    padding-bottom: 64px;

    @media (max-width: 767px) {
        padding-bottom: 48px;
    }

    @media (max-width: 550px) {
        padding-bottom: 32px;
    }
}

.pt-48px {
    padding-top: 48px;

    @media (max-width: 767px) {
        padding-top: 32px;
    }

    @media (max-width: 550px) {
        padding-top: 24px;
    }
}

.pb-48px {
    padding-bottom: 48px;

    @media (max-width: 767px) {
        padding-bottom: 32px;
    }

    @media (max-width: 550px) {
        padding-bottom: 24px;
    }
}

.mb-64px {
    margin-bottom: 64px;

    @media (max-width: 767px) {
        margin-bottom: 48px;
    }

    @media (max-width: 550px) {
        margin-bottom: 32px;
    }
}

.mb-16px * {
    margin-bottom: 16px !important;
}

.gap-0 {
    gap: 0 !important;
}

.pl-15 {
    padding-left: 15px !important;
}


.hero-bg {
    background: linear-gradient(0deg, #f6f4ff 0%, #f6f4ff 100%);
}

.bg-white {
    background-color: #fff;
}

.opacity-0 {
    opacity: 0;
}