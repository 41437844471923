/* footer top section rounded knob*/
.signature-row {
    background: linear-gradient(90deg, transparent 50%, #262525 50%);
    padding: 25px;
    display: flex;

    .container-sr {
        display: flex;
        max-width: 1000px;
        margin: 0 auto;
        gap: 25px;
    }

    .col-sr {
        flex: 0 0 50%;

        &:nth-child(1) {
            display: flex;
            justify-content: flex-end;
            padding-right: 60px;
        }

        &:nth-child(2) {
            color: white;
            padding-left: 60px;

            p {
                max-width: 500px;
            }
        }
    }

    &.white {
        background: transparent;

        p {
            color: black;
        }
    }

    @media (max-width: 991px) {
        flex-direction: column;
        background: initial;
        padding: 0;
        text-align: center;
        .col-sr {
            &:nth-child(1) {
                justify-content: center;
                flex: auto;
                padding: 25px;
            }

            &:nth-child(2) {
                padding: 25px;
                background-color: #3c3b3b;

                p {
                    margin: 0 auto 25px;
                }

                .d-flex {
                    justify-content: center;
                }
            }
        }
        &.white {
            background: transparent;

            .col-sr {
                &:nth-child(2) {
                    background-color: initial;

                    p {
                        color: black;
                    }
                }
            }
        }
    }
}

/* circle-knob */
.trophy {
    position: relative;
    height: 125px;
    width: 125px;
    display: flex;
    justify-content: flex-end;

    .circle-knob,
    .paw-black {
        position: absolute;
    }

    .circle-knob svg,
    .paw-black svg {
        height: 125px;
    }
}

/* footer bottom section main-footer*/
.main-footer {
    background-color: #fff;
    border-top: 1px solid $grey-40;
    padding: 32px 0;

    @media (min-width: 575px) {
        padding: 16px 0;
    }


    .footer {
        display: flex;
        justify-content: center;

        &__logo--mobile {
            display: none;
        }

        &__left {
            display: flex;
            gap: 48px;
            justify-content: flex-end;
            align-items: center;
            flex: 1;
            @media(max-width: 991px) {
                gap: 15px;
                justify-content: center;
                width: 100%;
                flex-wrap: wrap;

            }
            @media (max-width: 575px) {
                flex-direction: column;
                order: 1;
            }
        }

        &__right {
            display: flex;
            gap: 48px;
            justify-content: flex-start;
            align-items: center;
            flex: 1;

            &--mobile-link {
                display: none;
            }

            @media(max-width: 991px) {
                gap: 0;
                justify-content: space-between;
                width: 100%;
            }
        }
    }

    .logo-footer {
        display: flex;
        align-items: flex-end;
        position: relative;
        margin: 0 64px;
        @media (max-width: 1024px) {
            margin: 0 54px;
        }

        &::after {
            content: "\00AE";
            font-size: 0.875rem;
            font-weight: 200;
            color: $grey-120;
            display: block;
            position: absolute;
            top: 0;
            right: -0.875rem;
        }

        .logo-img {
            width: 91px;
        }
    }

    .footer-link {
        font-family: $font-content;
        font-weight: 300;
        font-size: rem(16);
        color: $grey-120;
        text-decoration: none;
        padding: 4px 10px;
        position: relative;
        z-index: 1;

        & span:nth-child(2) {
            position: absolute !important;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            z-index: 9;
            transition: all .2s ease;
        }


        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: inherit;
            transition: all .2s ease;
            z-index: -1;

        }

        &:hover::before {
            filter: blur(5px);
            background-color: #dbe0ff;
        }
    }


}

@media(max-width: 767px) {
    .main-footer {
        .footer {
            flex-direction: column;
            align-items: center;

            &__logo--desktop {
                display: none;
            }

            &__logo--mobile {
                display: block;
                margin-bottom: 32px;
                @media (max-width: 575px) {
                    order: 2;
                    margin-bottom: 0;
                    margin-top: 32px;
                }
            }

            &__right {
                display: none;

                &--mobile-link {
                    display: inline;
                }
            }

        }
    }
}

.landing-footer {
    border-top: 1px solid #e5e5e5;
    background: #fff;
    padding: 32px 0;

    & img {
        height: 41px;
    }
}
